import _ from 'lodash';
import React from 'react';
import { GridColumn, Search } from 'semantic-ui-react';
import '../application.scss';
import { useField } from 'formik';

const initialState = {
  loadingMake: false,
  makeResults: [],
  make: '',
  makeInputValue: '',
};

function makeReducer(state, action) {
  switch (action.type) {
    case 'CLEAN_QUERY':
      return initialState;

    case 'START_MAKE_SEARCH':
      return {
        ...state,
        loadingMake: true,
        makeInputValue: action.query,
        model: '',
        modelInputValue: '',
      };
    case 'FINISH_MAKE_SEARCH':
      return { ...state, loadingMake: false, makeResults: action.results };
    case 'UPDATE_MAKE_SELECTION':
      return {
        ...state,
        make: action.selection,
        model: '',
        modelInputValue: '',
      };
    case 'UPDATE_MAKE_INPUT':
      return {
        ...state,
        makeInputValue: action.value,
        model: '',
        modelInputValue: '',
      };
    default:
      throw new Error();
  }
}
const MakeSelection = ({ id, handleMakeChange = null, value, disabled }) => {
  const [, meta, helpers] = useField({ name: id });
  const { error, touched } = meta;
  const { setValue } = helpers;

  const [state, dispatch] = React.useReducer(makeReducer, initialState);

  const { loadingMake, makeResults, makeInputValue } = state;

  const timeoutRef = React.useRef();

  React.useEffect(() => {
    if (value !== '') {
      dispatch({ type: 'UPDATE_MAKE_INPUT', value });
      setValue(value);
    }
  }, [value, setValue]);

  const handleMakeSearchChange = React.useCallback((e, data) => {
    clearTimeout(timeoutRef.current);
    dispatch({ type: 'START_MAKE_SEARCH', query: data.value });

    timeoutRef.current = setTimeout(async () => {
      if (data.value.length === 0) {
        dispatch({ type: 'CLEAN_QUERY' });
        return;
      }

      const inputValue = _.escapeRegExp(data.value.trim());
      const re = new RegExp(inputValue, 'i');

      try {
        const response = await fetch(
          'https://vpic.nhtsa.dot.gov/api/vehicles/GetMakesForVehicleType/car?format=json'
        );
        const { Results } = await response.json();

        const filteredResults = Results.filter((make) =>
          re.test(make.MakeName)
        ).map(({ MakeName }) => ({
          title: MakeName,
        }));
        dispatch({ type: 'FINISH_MAKE_SEARCH', results: filteredResults });
      } catch (error) {
        console.error('Error fetching make data:', error);
        dispatch({ type: 'FINISH_MAKE_SEARCH', results: [] });
      }
    }, 300);
  }, []);

  const handleMakeSelection = (e, data) => {
    handleMakeChange(data.result.title);
    dispatch({ type: 'UPDATE_MAKE_SELECTION', selection: data.result.title });
    dispatch({ type: 'UPDATE_MAKE_INPUT', value: data.result.title });
    setValue(data.result.title);
  };

  return (
    <GridColumn width={4}>
      <div className="make-model-segment">
        <div className="custom-label">
          <label className="required-stars">Make</label>
        </div>
        <Search
          id="make"
          name="make"
          className="cus-search-bar"
          loading={loadingMake}
          placeholder="Eg - Ford"
          onResultSelect={(e, data) => {
            handleMakeSelection(e, data);
          }}
          onSearchChange={(e, data) => {
            handleMakeSearchChange(e, data);
            handleMakeChange(data.value);
            setValue(data.value);
          }}
          results={makeResults}
          value={makeInputValue}
        />
        {error && touched && <div className="invalid-feedback">{error}</div>}
      </div>
    </GridColumn>
  );
};

export default MakeSelection;
