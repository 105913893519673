import { jwtDecode } from 'jwt-decode';

const isTokenExpired = (token) => {
  if (!token) return true;
  try {
    const decodedToken = jwtDecode(token);
    if (!decodedToken.exp) return true;
    return decodedToken.exp * 1000 < Date.now();
  } catch (error) {
    console.error('Token decoding error:', error);
    return true;
  }
};

export { isTokenExpired };
