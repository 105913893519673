/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useEffect, useState } from 'react';
import { useField } from 'formik';
import {
  Grid,
  GridRow,
  GridColumn,
  Label,
  Icon,
  Button,
} from 'semantic-ui-react';
import DeletionModal from '../modals/deletionModal';

const FileUploadComponent = ({
  handleFileUpload = null,
  document,
  uploadDocumentInputRef = null,
  handleDocumentDelete,
  error = '',
  requiredStar = false,
  label,
  subLabel,
  readOnly = false,
  ...props
}) => {
  const [, meta, helpers] = useField(props);
  const { setValue } = helpers;

  const [deletionOpen, setDeletionOpen] = useState(false);
  const [localError, setLocalError] = useState('');

  useEffect(() => {
    if (document) {
      setValue(document);
    } else {
      setValue('');
    }
  }, [document, setValue]);

  const handleUploadDocumentButtonClick = () => {
    if (uploadDocumentInputRef && uploadDocumentInputRef.current) {
      uploadDocumentInputRef.current.click();
    }
  };

  const handleReplaceButtonClick = () => {
    if (uploadDocumentInputRef && uploadDocumentInputRef.current) {
      uploadDocumentInputRef.current.click();
    }
  };

  const handleDeletionClose = () => {
    setDeletionOpen(false);
  };

  const handleDeletion = () => {
    setDeletionOpen(false);
    handleDocumentDelete();
  };

  const handleDeletionModal = () => {
    setDeletionOpen(true);
  };

  const handleFileUploadChange = (event) => {
    const file = event.target.files[0];
    const maxSizeInBytes = 5242880;

    if (file) {
      if (file.size > maxSizeInBytes) {
        setLocalError('File size should not exceed 5MB');
      } else {
        setLocalError('');
        if (handleFileUpload) {
          handleFileUpload(event);
        }
      }
    }
  };

  const renderDocumentPreview = () => {
    if (document && document.file) {
      const objectUrl = URL.createObjectURL(document.file);
      if (document.file.type === 'application/pdf') {
        return (
          <div className="document-preview-container" key={objectUrl}>
            <iframe
              src={objectUrl}
              width="180"
              height="180"
              key={objectUrl}
              style={{ border: 'none' }}
            />
            {!readOnly && (
              <div className="document-actions">
                <Label
                  className="document-delete-button"
                  onClick={handleDeletionModal}
                >
                  <Icon name="trash alternate outline" />
                </Label>
              </div>
            )}
          </div>
        );
      } else {
        return (
          <div className="document-preview-container" key={objectUrl}>
            <img
              src={objectUrl}
              width="180"
              height="180"
              alt="Document preview"
              key={objectUrl}
            />
            {!readOnly && (
              <div className="document-actions">
                <Label
                  className="document-delete-button"
                  onClick={handleDeletionModal}
                >
                  <Icon name="trash alternate outline" />
                </Label>
              </div>
            )}
          </div>
        );
      }
    }
    if (document && document.url) {
      const isImage = (url) => {
        return /\.(jpg|jpeg|png|gif|bmp|svg)$/i.test(url);
      };

      return (
        <div className="document-preview-container" key={document.url}>
          {isImage(document.url) ? (
            <img
              src={document.url}
              width="180"
              height="180"
              alt="Document preview"
              style={{ objectFit: 'cover', border: 'none' }}
            />
          ) : (
            <iframe
              src={document.url}
              width="180"
              height="180"
              key={document.url}
              style={{ border: 'none' }}
            />
          )}
        </div>
      );
    }

    return null;
  };

  const containerClass = document
    ? 'vehicle-approval-container has-document'
    : 'vehicle-approval-container';

  return (
    <>
      <DeletionModal
        open={deletionOpen}
        header={`Delete ${label} Vehicle document`}
        content={`Are you sure you want to delete this ${label} document?`}
        close={handleDeletionClose}
        confirmation={handleDeletion}
      />

      <GridColumn>
        <Grid>
          <GridRow columns={1} style={{ paddingBottom: '0px' }}>
            <GridColumn>
              <label className={requiredStar ? 'required-stars' : ''}>
                {label} -<span className="small-label-gray">{subLabel}</span>
              </label>
            </GridColumn>
          </GridRow>
          <GridRow columns={1}>
            <GridColumn width={16}>
              <input
                type="file"
                accept=".jpg,.jpeg,.png,.pdf"
                style={{ display: 'none' }}
                ref={uploadDocumentInputRef}
                onChange={handleFileUploadChange}
                disabled={readOnly}
              />
              <div className="import-approval-layout">
                <div className={containerClass}>
                  {document
                    ? renderDocumentPreview()
                    : !readOnly && (
                        <div className="choose-button">
                          <Grid>
                            <GridColumn>
                              <GridRow
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              >
                                <Icon name="upload" className="upload-icon" />
                                <Button
                                  onClick={handleUploadDocumentButtonClick}
                                  primary
                                  className="upload-button"
                                >
                                  Upload
                                </Button>
                              </GridRow>
                              <GridRow
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              >
                                <label className="small-label-gray"></label>
                              </GridRow>
                              <GridRow
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                }}
                              >
                                <label className="small-label-gray">
                                  Supports JPG, JPEG, PNG and PDF
                                </label>
                              </GridRow>
                            </GridColumn>
                          </Grid>
                        </div>
                      )}
                </div>
              </div>
            </GridColumn>
          </GridRow>
          {(error || localError) && (
            <GridRow>
              <GridColumn>
                <GridRow columns={1}>
                  <GridColumn>
                    <div style={{ color: 'red', marginTop: '-20px' }}>
                      {localError || error}
                    </div>
                  </GridColumn>
                </GridRow>
              </GridColumn>
            </GridRow>
          )}
          {document && !readOnly && (
            <GridRow columns={1} style={{ paddingTop: '0px' }}>
              <GridColumn>
                <div className="replace-button">
                  <label
                    className="label-blue"
                    onClick={handleReplaceButtonClick}
                  >
                    <i className="sync icon"></i>
                    Replace
                  </label>
                </div>
              </GridColumn>
            </GridRow>
          )}
        </Grid>
      </GridColumn>
    </>
  );
};

export default FileUploadComponent;
