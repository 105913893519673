import { Formik } from 'formik';
import React, { useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import {
  Button,
  Checkbox,
  Form,
  Grid,
  GridColumn,
  GridRow,
  Icon,
  Popup,
  Segment,
} from 'semantic-ui-react';
import { CustomDropdown } from '../../../components/customDropdown';
import { CustomSegment } from '../../../components/customSegment';
import { CustomInput } from '../../../components/forms/inputs/customInput';
import '../../../components/styles.scss';

import ImageUploadComponent from '../../../components/fileUpload/imageUploadComponent';
import PdfUploadComponent from '../../../components/fileUpload/pdfUploadComponent';
import { useNavigate } from 'react-router-dom';
import { postVehicleImport } from '../../../store/modules/applications';
import { STATUS } from '../../../utils/constant';

import { v4 as uuidv4 } from 'uuid';
import '../application.scss';
import { isEmpty } from '../../../utils/lodash';
import {
  createWithFormData,
  sourceType,
  termsConditions,
} from '../../../utils';
import CustomDateComponent from '../../../components/customDateComponent';
import { VehicleDocumentTypes } from '../../../utils/vehicleImportConstant';
import VehicleImportValidationSchema, {
  VehicleImportInitialValues,
} from '../../../validations/vehicleImportForm/createVehicleImportValidation';
import CustomModalInfo from '../../../components/modals/customModalInfo';
import VehicleImportConfirmationModal from '../../../components/modals/vehicleImportConfirmationModal';
import MakeSelection from '../common/makeSelection';
import ModelSelection from '../common/modelSelection';
import PdfAndImageUploadComponent from '../../../components/fileUpload/pdfAndImageUploadComponent';
const VehicleImportAddForm = () => {
  const [estimatedDate, setEstimatedDate] = useState(null);

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [status, setStatus] = useState('');

  const inVehicleFileInputRef = useRef(null);
  const exVehicleFileInputRef = useRef(null);
  const ebVehicleFileInputRef = useRef(null);
  const vinPlateFileInputRef = useRef(null);

  const loader = useSelector((state) => state.applications.vehiclePostLoading);

  const [isTermsAgreed, setIsTermsAgreed] = useState(false);
  const [make, setMake] = useState('');

  const onDateChange = (value) => {
    setEstimatedDate(value);
  };

  const handleMakeChange = (value) => {
    setMake(value);
  };

  const [ebVehicleImages, setEbVehicleImages] = useState([]);
  const [vinPlateImages, setVinPlateImages] = useState([]);
  const [inVehicleImages, setInVehicleImages] = useState([]);
  const [exVehicleImages, setExVehicleImages] = useState([]);

  const [ebVehicleErrors, setEbVehicleErrors] = useState([]);
  const [vinPlateErrors, setVinPlateErrors] = useState([]);
  const [inVehicleErrors, setInVehicleErrors] = useState([]);
  const [exVehicleErrors, setExVehicleErrors] = useState([]);

  const [isVehicleOld, setIsVehicle] = useState(false);
  const [openTermsModal, setOpenTermsModal] = useState(false);

  const handleFileInputChange = (e, updateStateName, setErrorState, label) => {
    const files = e.target.files;
    const newImages = [];
    const newErrors = [];

    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      if (file.size > 5242880) {
        // Check if file size is greater than 5MB
        newErrors.push(`${file.name} is too large. Maximum size is 5MB.`);
        continue;
      }
      const id = uuidv4();
      newImages.push({ id, file });
    }

    setErrorState(newErrors); // Update the specific error state

    if (newImages.length > 0) {
      if (label === 'engineBay') {
        updateStateName([...ebVehicleImages, ...newImages]);
      } else if (label === 'vinPhotos') {
        updateStateName([...vinPlateImages, ...newImages]);
      } else if (label === 'interiorImage') {
        updateStateName([...inVehicleImages, ...newImages]);
      } else if (label === 'exteriorImage') {
        updateStateName([...exVehicleImages, ...newImages]);
      }
    }
  };

  const appendImagesToFormData = (formData, images, type) => {
    if (images) {
      for (const item of images) {
        formData.append(type, item.file);
      }
    }
  };

  const handleValidSubmit = async (values) => {
    try {
      const formData = new FormData();

      values.isMoreThan25 = isVehicleOld;
      values.estimatedDate = estimatedDate;
      values.status = status;
      values.isTermsAgreed = isTermsAgreed;
      createWithFormData(formData, values);
      if (isVehicleOld) {
        appendImagesToFormData(
          formData,
          vinPlateImages,
          VehicleDocumentTypes.vinImages
        );
        appendImagesToFormData(
          formData,
          ebVehicleImages,
          VehicleDocumentTypes.engineBayImages
        );
      }

      handleImageSet(
        formData,
        exVehicleImages,
        VehicleDocumentTypes.exteriorImages
      );

      handleImageSet(
        formData,
        inVehicleImages,
        VehicleDocumentTypes.interiorImages
      );

      appendFile(formData, 'auctionReport', auctionFile);
      appendFile(formData, 'exportCertificate', exportCertificate);
      appendFile(formData, 'vehicleImport', vehicleInvoice);

      await dispatch(postVehicleImport(formData));
      navigate('/applications');
    } catch (err) {
      console.error('Error in Vehicle Import: ' + err);
    }
  };

  const handleImageSet = (formData, images, type) => {
    if (!isEmpty(images)) {
      appendImagesToFormData(formData, images, type);
    }
  };

  const appendFile = (formData, key, file) => {
    if (!isEmpty(file)) {
      formData.set(key, file.file);
    }
  };

  const uploadExportCertificateInputRef = useRef(null);
  const uploadAuctionInputRef = useRef(null);
  const uploadVehicleInvoiceInputRef = useRef(null);

  const [exportCertificate, setExportCertificate] = useState(null);
  const [auctionFile, setAuctionFile] = useState(null);
  const [vehicleInvoice, setVehicleInvoice] = useState(null);

  const [exportCertificateError, setExportCertificateError] = useState('');
  const [auctionFileError, setAuctionFileError] = useState('');
  const [vehicleInvoiceError, setVehicleInvoiceError] = useState('');

  const [confirmationOpen, setConfirmationOpen] = useState(false);
  const [tempFormValues, setTempFormValues] = useState(null);

  const handleFileUpload = (event, setStateData, setErrorState) => {
    const file = event.target.files[0];
    if (file.size > 5242880) {
      // Check if file size is greater than 5MB
      setErrorState(`${file.name} is too large. Maximum size is 5MB.`);
    } else {
      setErrorState(''); // Clear any existing error messages
      setStateData({ file });
    }
  };

  const handleDocumentDelete = (updateState, inputRef) => {
    updateState(null);
    if (inputRef.current) {
      inputRef.current.value = null; // Clear the file input
    }
  };

  const onToggle = () => {
    setOpenTermsModal(!openTermsModal);
  };

  const handelConfirmationClose = () => {
    setConfirmationOpen(false);
  };

  const handelConfirmation = () => {
    setConfirmationOpen(false);
    if (tempFormValues) {
      handleValidSubmit(tempFormValues);
      setTempFormValues(null);
    }
  };

  const handelConfirmationModel = (values) => {
    setTempFormValues(values);
    setConfirmationOpen(true);
  };

  return (
    <div className="vehicle-import-application">
      <div className="update-application">
        <CustomModalInfo
          open={openTermsModal}
          header="Terms & Conditions"
          content={termsConditions()}
          onToggle={onToggle}
        />

        <VehicleImportConfirmationModal
          open={confirmationOpen}
          header="Confirmation"
          values={tempFormValues}
          close={handelConfirmationClose}
          confirmation={handelConfirmation}
        />

        <Formik
          initialValues={VehicleImportInitialValues}
          validationSchema={VehicleImportValidationSchema(isVehicleOld)}
          onSubmit={(values) => {
            values.estimatedDate = estimatedDate;
            values.isMoreThan25 = isVehicleOld;
            handelConfirmationModel(values);
          }}
        >
          {({ handleSubmit, isSubmitting, errors }) => (
            <Form onSubmit={handleSubmit}>
              {/* Vehicle Import Application Form */}
              <Segment
                className="update-application-form-layout"
                attached
                loading={loader}
              >
                <Grid className="update-application-form" stackable={true}>
                  <GridRow>
                    <GridColumn width={8}>
                      <div className="inline-container">
                        <div className="circle-container">1</div>
                        <div>
                          <label className="large-label-black">
                            Vehicle Details
                          </label>
                        </div>
                      </div>
                    </GridColumn>
                  </GridRow>
                  {/* custom input row 1 */}
                  <GridRow columns={4} style={{ paddingBottom: '0px' }}>
                    <GridColumn width={4}>
                    <CustomInput
                        id="vinNo"
                        name="vinNo"
                        labelText={
                          <Popup
                            trigger={<span>VIN/Chassis Number</span>}
                            content="Vehicle Identification Number"
                          />
                        }
                        requiredStar
                      />
                    </GridColumn>
                    <GridColumn width={4}>
                      <div className="date-of-arrival">
                        <div className="custom-label">
                          <label className="required-stars">
                            Estimated Date of Arrival
                          </label>
                        </div>
                        <div>
                          <CustomDateComponent
                            id="estimatedDate"
                            name="estimatedDate"
                            value={estimatedDate}
                            onChange={(e) => onDateChange(e)}
                          />
                        </div>
                      </div>
                    </GridColumn>

                    <MakeSelection
                      id="make"
                      handleMakeChange={(e) => handleMakeChange(e)}
                    />
                    <ModelSelection id="model" selectedMake={make} />
                  </GridRow>
                  {/* custom input row 2 */}
                  <GridRow columns={4} style={{ paddingBottom: '0px' }}>
                    <GridColumn width={4}>
                      <div className="report-type-drop">
                        <label className="required-stars">
                          Power Source Type
                        </label>
                        <div>
                          <CustomDropdown
                            id="powerSourceType"
                            name="powerSourceType"
                            className="custom-drop-down"
                            options={sourceType}
                            placeholder="Choose an option"
                            selection
                          />
                        </div>
                      </div>
                    </GridColumn>
                    <GridColumn width={4}>
                      <CustomInput
                        id="totalNoOfSeats"
                        name="totalNoOfSeats"
                        labelText="Total No of Seats"
                        placeholder="Enter total no of seats"
                        //disabled={true}
                        requiredStar
                      />
                    </GridColumn>
                    <GridColumn width={8}>
                      <CustomInput
                        id="notes"
                        name="notes"
                        labelText="Notes"
                        placeholder="Any comments for the reviewer"
                        //requiredStar
                      />
                    </GridColumn>
                  </GridRow>
                  {/* custom input row 3 */}
                  <GridRow
                    columns={1}
                    style={{ paddingBottom: '20px', paddingTop: '30px' }}
                  >
                    <GridColumn
                      width={4}
                      style={{ display: 'flex', alineItems: 'start' }}
                    >
                      <div className="custom-check-box">
                        <Checkbox
                          id="isMoreThan25"
                          name="isMoreThan25"
                          label="Is this vehicle older than 25 years"
                          checked={isVehicleOld}
                          onChange={() => setIsVehicle(!isVehicleOld)}
                        />
                      </div>
                    </GridColumn>  
                  </GridRow>
                    {isVehicleOld ? (
                      <div className='vehicle-year'>One clear colour image of the front, rear, sides, engine bay, Interior of the vehicle.<br></br>One clear colour photo of the VIN or chassis number on the vehicle.</div>
                      ):(
                      <div className='vehicle-year'>One clear colour image of the front, rear and sides of the vehicle. </div>
                    )}
                  <GridRow>
                    <GridColumn width={8}>
                      <div className="inline-container">
                        <div className="circle-container">2</div>
                        <div>
                          <label className="large-label-black">
                            Vehicle Photos
                          </label>
                        </div>
                      </div>
                    </GridColumn>
                  </GridRow>
                  {isVehicleOld && (
                    <GridRow>
                      <GridColumn width={8}>
                        {/* VIN Plate photos */}
                        <ImageUploadComponent
                          id="vinImages"
                          name="vinImages"
                          images={vinPlateImages}
                          setImages={setVinPlateImages}
                          label="Vin Photos"
                          inputRef={vinPlateFileInputRef}
                          onInputChange={(e) =>
                            handleFileInputChange(
                              e,
                              setVinPlateImages,
                              setVinPlateErrors,
                              'vinPhotos'
                            )
                          }
                          smallContainer={true}
                          uploadErrors={vinPlateErrors}
                          requiredStar={isVehicleOld}
                        />
                      </GridColumn>
                      <GridColumn width={8}>
                        {/* Engine bay photos */}
                        <ImageUploadComponent
                          id="engineBayImages"
                          name="engineBayImages"
                          images={ebVehicleImages}
                          setImages={setEbVehicleImages}
                          label="Engine bay photos"
                          inputRef={ebVehicleFileInputRef}
                          onInputChange={(e) =>
                            handleFileInputChange(
                              e,
                              setEbVehicleImages,
                              setEbVehicleErrors,
                              'engineBay'
                            )
                          }
                          smallContainer={true}
                          uploadErrors={ebVehicleErrors}
                          requiredStar={isVehicleOld}
                        />
                      </GridColumn>
                    </GridRow>
                  )}
                  {/* <InternalVehiclePhotos /> */}
                  <ImageUploadComponent
                    name="interiorImages"
                    images={inVehicleImages}
                    setImages={setInVehicleImages}
                    label="Internal Vehicle Photos"
                    inputRef={inVehicleFileInputRef}
                    onInputChange={(e) =>
                      handleFileInputChange(
                        e,
                        setInVehicleImages,
                        setInVehicleErrors,
                        'interiorImage'
                      )
                    }
                    uploadErrors={inVehicleErrors}
                    requiredStar={isVehicleOld}
                  />

                  {/* External vehicle photos */}
                  <ImageUploadComponent
                    id="exteriorImages"
                    name="exteriorImages"
                    images={exVehicleImages}
                    setImages={setExVehicleImages}
                    label="Exterior Vehicle photos"
                    inputRef={exVehicleFileInputRef}
                    onInputChange={(e) =>
                      handleFileInputChange(
                        e,
                        setExVehicleImages,
                        setExVehicleErrors,
                        'exteriorImage'
                      )
                    }
                    uploadErrors={exVehicleErrors}
                    requiredStar
                  />
                  {/* Vehicle Documents */}
                  <GridRow columns={4} style={{ paddingBottom: '0px' }}>
                    <GridColumn width={4}>
                      {/* Export certificate */}
                      <PdfAndImageUploadComponent
                        id="exportCertificate"
                        name="exportCertificate"
                        label="Export certificate BB"
                        type="export_certificate"
                        handleFileUpload={(e) =>
                          handleFileUpload(
                            e,
                            setExportCertificate,
                            setExportCertificateError
                          )
                        }
                        document={exportCertificate}
                        uploadDocumentInputRef={uploadExportCertificateInputRef}
                        handleDocumentDelete={() =>
                          handleDocumentDelete(
                            setExportCertificate,
                            uploadExportCertificateInputRef
                          )
                        }
                        uploadError={exportCertificateError}
                        requiredStar
                      />
                    </GridColumn>
                    <GridColumn width={4}>
                      {/* Auction report */}
                      <PdfAndImageUploadComponent
                        id="auctionReport"
                        name="auctionReport"
                        label="Auction report"
                        type="auction_report"
                        handleFileUpload={(e) =>
                          handleFileUpload(
                            e,
                            setAuctionFile,
                            setAuctionFileError
                          )
                        }
                        document={auctionFile}
                        uploadDocumentInputRef={uploadAuctionInputRef}
                        handleDocumentDelete={() =>
                          handleDocumentDelete(
                            setAuctionFile,
                            uploadAuctionInputRef
                          )
                        }
                        uploadError={auctionFileError}
                        requiredStar
                      />
                    </GridColumn>
                    <GridColumn width={4}>
                      {/* Vehicle Invoice */}
                      <PdfUploadComponent
                        id="vehicleImport"
                        name="vehicleImport"
                        label="Vehicle invoice"
                        type="vehicle_invoice"
                        handleFileUpload={(e) =>
                          handleFileUpload(
                            e,
                            setVehicleInvoice,
                            setVehicleInvoiceError
                          )
                        }
                        document={vehicleInvoice}
                        uploadDocumentInputRef={uploadVehicleInvoiceInputRef}
                        handleDocumentDelete={() =>
                          handleDocumentDelete(
                            setVehicleInvoice,
                            uploadVehicleInvoiceInputRef
                          )
                        }
                        uploadError={vehicleInvoiceError}
                        requiredStar
                        onlyPdf
                      />
                    </GridColumn>
                  </GridRow>
                </Grid>
              </Segment>

              {/* Footer Section */}
              <Segment
                className="update-application-footer"
                attached="bottom"
                disabled={loader}
              >
                <Grid stackable>
                  <GridRow>
                    <GridColumn width={4}>
                      <div className="download-button"></div>
                    </GridColumn>
                    <GridColumn width={7}></GridColumn>
                    <GridColumn width={5}>
                      <Grid>
                        <GridRow>
                          <GridColumn width={8}>
                            <div className="custom-check-box">
                              <Checkbox
                                name="termsConditions"
                                label=""
                                value={isTermsAgreed}
                                //onChange={handelTermsConditions}
                                onChange={() =>
                                  setIsTermsAgreed(!isTermsAgreed)
                                }
                              />
                              <label
                                className="terms"
                                onClick={() => setOpenTermsModal(true)}
                              >
                                Terms & Conditions
                              </label>
                            </div>
                          </GridColumn>

                          <GridColumn
                            width={8}
                            style={{
                              display: 'flex',
                              justifyContent: 'flex-end',
                            }}
                          >
                            <div className="submit-button">
                              <Button
                                secondary
                                loading={status === STATUS.SUBMITTED && loader}
                                disabled={
                                  (status === STATUS.SUBMITTED && loader) ||
                                  !isTermsAgreed
                                }
                                onClick={() => setStatus(STATUS.SUBMITTED)}
                              >
                                Submit Application
                              </Button>
                            </div>
                          </GridColumn>
                        </GridRow>
                      </Grid>
                    </GridColumn>
                  </GridRow>
                </Grid>
              </Segment>
            </Form>
          )}
        </Formik>
      </div>
    </div>
  );
};
const VehicleImportApplicationForm = () => {
  const navigate = useNavigate();
  return (
    <>
      <div>
        <div className="application-address-back">
          <label
            className="back-text-color"
            onClick={() => navigate('/applications')}
          >
            <Icon
              name="arrow left"
              className="application-address-back-arrow"
            />
            Back to Applications
          </label>
        </div>
      </div>
      <CustomSegment
        title="Creating New Vehicle Import Application"
        children={<VehicleImportAddForm />}
      />
    </>
  );
};

export default VehicleImportApplicationForm;
