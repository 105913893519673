import * as Yup from 'yup';
import { find, get, isEmpty } from '../../utils/lodash';

export const complianceInitialValues = {
  chassisNo: '',
  vehicleArrivedDate: '',
  engineNo: '',
  transmissionType: '',
  make: '',
  model: '',
  odometerReading: '',
  noOfDoors: '',
  totalNoOfSeats: '',
  notes: '',
  frontTyreSize: '',
  frontTyreLoad: '',
  rearTyreSize: '',
  rearTyreLoad: '',
};

export const confirmComplianceValue = (responses) => {
  if (!responses) {
    return {
      chassisNo: '',
      vehicleArrivedDate: '',
      engineNo: '',
      transmissionType: '',
      make: '',
      model: '',
      odometerReading: '',
      noOfDoors: '',
      totalNoOfSeats: '',
      notes: '',
      frontTyreSize: '',
      frontTyreLoad: '',
      rearTyreSize: '',
      rearTyreLoad: '',
      approvalDocument: '',
    };
  }

  return {
    chassisNo: responses.chassisNo || '',
    vehicleArrivedDate: responses.vehicleArrivedDate || '',
    engineNo: responses.engineNo || '',
    transmissionType: responses.transmissionType || '',
    make: responses.make || '',
    model: responses.model || '',
    odometerReading: responses.odometerReading || '',
    noOfDoors: responses.noOfDoors || '',
    totalNoOfSeats: responses.totalNoOfSeats || '',
    notes: responses.notes || '',
    frontTyreSize: responses.frontTyreSize || '',
    frontTyreLoad: responses.frontTyreLoad || '',
    rearTyreSize: responses.rearTyreSize || '',
    rearTyreLoad: responses.rearTyreLoad || '',
    approvalDocument: responses.approvalDocument || '',
  };
};

export const complianceValidationSchemaStep1 = Yup.object({
  chassisNo: Yup.string().required('Chassis No is required'),
  vehicleArrivedDate: Yup.date().required('Vehicle Arrived Date is required'),
  engineNo: Yup.string().required('Engine No is required'),
  transmissionType: Yup.string().required('Transmission Type is required'),
  make: Yup.string().required('Make is required'),
  model: Yup.string().required('Model is required'),
  odometerReading: Yup.string().required('Odometer Reading is required'),
  noOfDoors: Yup.string().required('No Of Doors is required'),
  totalNoOfSeats: Yup.string().required('Total No Of Seats is required'),
  notes: Yup.string().max(100, 'Notes must be at most 100 words'),
  frontTyreSize: Yup.string().required('Front Tyre Size is required'),
  frontTyreLoad: Yup.string().required('Front Tyre Load is required'),
  rearTyreSize: Yup.string().required('Rear Tyre Size is required'),
  rearTyreLoad: Yup.string().required('Rear Tyre Load is required'),
});

export const complianceValidationSchemaStep2 = Yup.object({
  approvalDocument: Yup.mixed().required('This field is mandatory'),
  engravedVin: Yup.mixed().required('This field is mandatory'),
  passengerSideAirbag: Yup.mixed().required('This field is mandatory'),
  compliancePlate: Yup.mixed().required('This field is mandatory'),
  australianRoadPlate: Yup.mixed().required('This field is mandatory'),
  tyreOne: Yup.mixed().required('This field is mandatory'),
  tyreTwo: Yup.mixed().required('This field is mandatory'),
  tyreThree: Yup.mixed().required('This field is mandatory'),
  tyreFour: Yup.mixed().required('This field is mandatory'),
  petrolOrDiesel: Yup.mixed().required('This field is mandatory'),
  odometerReadingUpload: Yup.mixed().required('This field is mandatory'),
  undercarriageFront: Yup.mixed().required('This field is mandatory'),
  undercarriageRear: Yup.mixed().required('This field is mandatory'),
  undercarriageLeft: Yup.mixed().required('This field is mandatory'),
  undercarriageRight: Yup.mixed().required('This field is mandatory'),
  undercarriageCenter: Yup.mixed().required('This field is mandatory'),
});

export const updateComplianceValidationSchema = (responses, comments = []) => {
  const chassisNo = find(responses, (item) => item.attributeId === 16);
  const vehicleArrivedDate = find(responses, (item) => item.attributeId === 17);
  const engineNo = find(responses, (item) => item.attributeId === 18);
  const transmissionType = find(responses, (item) => item.attributeId === 19);
  const make = find(responses, (item) => item.attributeId === 20);
  const model = find(responses, (item) => item.attributeId === 21);
  const odometerReading = find(responses, (item) => item.attributeId === 22);
  const noOfDoors = find(responses, (item) => item.attributeId === 23);
  const totalNoOfSeats = find(responses, (item) => item.attributeId === 24);
  const notes = find(responses, (item) => item.attributeId === 25);
  const frontTyreSize = find(responses, (item) => item.attributeId === 26);
  const frontTyreLoad = find(responses, (item) => item.attributeId === 27);
  const rearTyreSize = find(responses, (item) => item.attributeId === 28);
  const rearTyreLoad = find(responses, (item) => item.attributeId === 29);
  const approvalDocument = find(responses, (item) => item.attributeId === 30);
  const engravedVin = find(responses, (item) => item.attributeId === 31);
  const passengerSideAirbag = find(
    responses,
    (item) => item.attributeId === 32
  );
  const compliancePlate = find(responses, (item) => item.attributeId === 33);
  const australianRoadPlate = find(
    responses,
    (item) => item.attributeId === 34
  );
  const tyreOne = find(responses, (item) => item.attributeId === 35);
  const tyreTwo = find(responses, (item) => item.attributeId === 36);
  const tyreThree = find(responses, (item) => item.attributeId === 37);
  const tyreFour = find(responses, (item) => item.attributeId === 38);
  const petrolOrDiesel = find(responses, (item) => item.attributeId === 39);
  const odometerReadingUpload = find(
    responses,
    (item) => item.attributeId === 40
  );
  const undercarriageFront = find(responses, (item) => item.attributeId === 41);
  const undercarriageRear = find(responses, (item) => item.attributeId === 42);
  const undercarriageLeft = find(responses, (item) => item.attributeId === 43);
  const undercarriageRight = find(responses, (item) => item.attributeId === 44);
  const undercarriageCenter = find(
    responses,
    (item) => item.attributeId === 45
  );
  const childAnchorPoints = find(responses, (item) => item.attributeId === 46);
  const headlights = find(responses, (item) => item.attributeId === 47);
  const towConnector = find(responses, (item) => item.attributeId === 48);
  return {
    chassisNo: get(chassisNo, 'attributeValue', ''),
    vehicleArrivedDate: get(vehicleArrivedDate, 'attributeValue', ''),
    engineNo: get(engineNo, 'attributeValue', ''),
    transmissionType: get(transmissionType, 'attributeValue', ''),
    make: get(make, 'attributeValue', ''),
    model: get(model, 'attributeValue', ''),
    odometerReading: get(odometerReading, 'attributeValue', ''),
    noOfDoors: get(noOfDoors, 'attributeValue', ''),
    totalNoOfSeats: get(totalNoOfSeats, 'attributeValue', ''),
    notes: get(notes, 'attributeValue', ''),
    frontTyreSize: get(frontTyreSize, 'attributeValue', ''),
    frontTyreLoad: get(frontTyreLoad, 'attributeValue', ''),
    rearTyreSize: get(rearTyreSize, 'attributeValue', ''),
    rearTyreLoad: get(rearTyreLoad, 'attributeValue', ''),
    approvalDocument: get(approvalDocument, 'multipleValues', ''),
    engravedVin: get(engravedVin, 'multipleValues', ''),
    passengerSideAirbag: get(passengerSideAirbag, 'multipleValues', ''),
    compliancePlate: get(compliancePlate, 'multipleValues', ''),
    australianRoadPlate: get(australianRoadPlate, 'multipleValues', ''),
    tyreOne: get(tyreOne, 'multipleValues', ''),
    tyreTwo: get(tyreTwo, 'multipleValues', ''),
    tyreThree: get(tyreThree, 'multipleValues', ''),
    tyreFour: get(tyreFour, 'multipleValues', ''),
    petrolOrDiesel: get(petrolOrDiesel, 'multipleValues', ''),
    odometerReadingUpload: get(odometerReadingUpload, 'multipleValues', ''),
    undercarriageFront: get(undercarriageFront, 'multipleValues', ''),
    undercarriageRear: get(undercarriageRear, 'multipleValues', ''),
    undercarriageLeft: get(undercarriageLeft, 'multipleValues', ''),
    undercarriageRight: get(undercarriageRight, 'multipleValues', ''),
    undercarriageCenter: get(undercarriageCenter, 'multipleValues', ''),
    childAnchorPoints: get(childAnchorPoints, 'multipleValues', ''),
    headlights: get(headlights, 'multipleValues', ''),
    towConnector: get(headlights, 'multipleValues', ''),
    comments: !isEmpty(comments) ? comments[0].commentText : '',
  };
};
