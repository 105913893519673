import React from 'react';
import { Grid, GridColumn, GridRow, Segment, Form } from 'semantic-ui-react';
import dayjs from 'dayjs';

import { CustomInput } from '../../../components/forms/inputs/customInput';
import CustomDateComponent from '../../../components/customDateComponent';
import MakeSelection from '../common/makeSelection';
import ModelSelection from '../common/modelSelection';
import { CustomDropdown } from '../../../components/customDropdown';
import { transmissionTypeOptions } from '../../../utils';

const Step1Form = ({
  vehicleArrivedDate,
  onDateChange,
  make,
  handleMakeChange,
  formikProps,
  readOnly,
  updateData,
}) => {
  const handleInputChange = (event) => {
    if (!readOnly) {
      const { name, value } = event.target;
      formikProps.handleChange(event);
      updateSessionStorage(name, value);
    }
  };

  // Function to update sessionStorage with current form values
  const updateSessionStorage = (name, value) => {
    const storedForm =
      JSON.parse(sessionStorage.getItem('complianceForm')) || {};
    const updatedForm = { ...storedForm, [name]: value };
    sessionStorage.setItem('complianceForm', JSON.stringify(updatedForm));
  };

  // Handle date changes for date input
  const handleDateChange = (date) => {
    if (!readOnly) {
      onDateChange(date ? dayjs(date).format('YYYY-MM-DD') : null);
      updateSessionStorage(
        'vehicleArrivedDate',
        date ? dayjs(date).format('YYYY-MM-DD') : null
      );
    }
  };

  return (
    <Segment className="update-application-form-layout" attached>
      <div className="flex-container">
        <h2 className="compliance-title">Compliance Application</h2>
        <h4 className="step-indicator">Step 1 of 2</h4>
      </div>
      <hr></hr>
      <Form>
        <Grid className="update-application-form" stackable>
          <GridRow columns={4}>
            <GridColumn width={4}>
              <CustomInput
                id="chassisNo"
                name="chassisNo"
                labelText="Chassis Number"
                placeholder="Enter chassis number"
                requiredStar
                value={formikProps.values.chassisNo}
                onChange={handleInputChange}
                disabled={readOnly}
              />
            </GridColumn>
            {updateData ? (
              <GridColumn width={4}>
                <div className="date-of-arrival">
                  <label className="required-stars">
                    Date your vehicle arrived in Australia
                  </label>
                  <CustomInput
                    id="vehicleArrivedDate"
                    name="vehicleArrivedDate"
                    placeholder="yyyy-mm-dd"
                    value={
                      vehicleArrivedDate ? dayjs(vehicleArrivedDate) : null
                    }
                    onChange={handleDateChange}
                    disabled={readOnly}
                  />
                </div>
              </GridColumn>
            ) : (
              <GridColumn width={4}>
                <div className="date-of-arrival">
                  <label className="required-stars">
                    Date your vehicle arrived in Australia
                  </label>
                  <CustomDateComponent
                    value={
                      vehicleArrivedDate ? dayjs(vehicleArrivedDate) : null
                    }
                    onChange={handleDateChange}
                    name="vehicleArrivedDate"
                    id="vehicleArrivedDate"
                    placeholder="yyyy-mm-dd"
                    disabled={readOnly}
                  />
                </div>
              </GridColumn>
            )}

            <GridColumn width={4}>
              <CustomInput
                id="engineNo"
                name="engineNo"
                labelText="Engine number"
                placeholder="Enter your vehicle’s engine number"
                requiredStar
                value={formikProps.values.engineNo}
                onChange={handleInputChange}
                disabled={readOnly}
              />
            </GridColumn>
            <GridColumn width={4}>
              <label className="required-stars">Transmission Type</label>
              <CustomDropdown
                className="custom-drop-down"
                name="transmissionType"
                options={transmissionTypeOptions}
                placeholder="Select"
                value={formikProps.values.transmissionType}
                onChange={(e, { name, value }) => {
                  formikProps.setFieldValue(name, value);
                  updateSessionStorage(name, value);
                }}
                disabled={readOnly}
              />
            </GridColumn>
          </GridRow>

          {/* Second Row */}
          <GridRow columns={4}>
            <GridColumn width={4}>
              <MakeSelection
                id="make"
                label="Make"
                placeholder="Eg - Ford"
                handleMakeChange={handleMakeChange}
                value={formikProps.values.make}
                requiredStar
                onChange={(value) => {
                  handleMakeChange(value);
                  updateSessionStorage('make', value);
                }}
                disabled={readOnly}
              />
            </GridColumn>
            <GridColumn width={4}>
              <ModelSelection
                id="model"
                label="Model"
                placeholder="Eg - Raptor"
                selectedMake={make}
                value={formikProps.values.model}
                requiredStar
                onChange={handleInputChange}
                disabled={readOnly}
              />
            </GridColumn>
            <GridColumn width={4}>
              <CustomInput
                id="odometerReading"
                name="odometerReading"
                labelText="Odometer reading"
                placeholder="Current Odometer reading"
                requiredStar
                value={formikProps.values.odometerReading}
                onChange={handleInputChange}
                disabled={readOnly}
              />
            </GridColumn>
            <GridColumn width={4}>
              <CustomInput
                id="noOfDoors"
                name="noOfDoors"
                labelText="Number of doors (Including gate/rear boot)"
                placeholder="Enter your vehicle’s door count"
                requiredStar
                value={formikProps.values.noOfDoors}
                onChange={handleInputChange}
                disabled={readOnly}
              />
            </GridColumn>
          </GridRow>

          {/* Third Row */}
          <GridRow columns={2}>
            <GridColumn width={4}>
              <CustomInput
                id="totalNoOfSeats"
                name="totalNoOfSeats"
                labelText="Total No of Seats"
                placeholder="Enter total no of seats"
                requiredStar
                value={formikProps.values.totalNoOfSeats}
                onChange={handleInputChange}
                disabled={readOnly}
              />
            </GridColumn>
            <GridColumn width={4}>
              <CustomInput
                id="notes"
                name="notes"
                labelText="Notes"
                placeholder="Any comments for the reviewer"
                value={formikProps.values.notes}
                onChange={handleInputChange}
                disabled={readOnly}
              />
            </GridColumn>
          </GridRow>

          {/* Fourth Row */}
          <GridRow columns={1}>
            <GridColumn width={16}>
              <div className="sub-topic">
                <label>Enter Factory Tyre placard Specs details below</label>
              </div>
            </GridColumn>
          </GridRow>

          {/* Fifth Row */}
          <GridRow columns={4} className="last-input-row">
            <GridColumn width={4}>
              <CustomInput
                id="frontTyreSize"
                name="frontTyreSize"
                labelText="Front tyre size"
                placeholder="Enter front tyre size"
                requiredStar
                value={formikProps.values.frontTyreSize}
                onChange={handleInputChange}
                disabled={readOnly}
              />
            </GridColumn>
            <GridColumn width={4}>
              <CustomInput
                id="frontTyreLoad"
                name="frontTyreLoad"
                labelText="Front tyre load & speed rating"
                placeholder="Enter front tyre load & speed rating"
                requiredStar
                value={formikProps.values.frontTyreLoad}
                onChange={handleInputChange}
                disabled={readOnly}
              />
            </GridColumn>
            <GridColumn width={4}>
              <CustomInput
                id="rearTyreSize"
                name="rearTyreSize"
                labelText="Rear tyre size"
                placeholder="Enter rear tyre size"
                requiredStar
                value={formikProps.values.rearTyreSize}
                onChange={handleInputChange}
                disabled={readOnly}
              />
            </GridColumn>
            <GridColumn width={4}>
              <CustomInput
                id="rearTyreLoad"
                name="rearTyreLoad"
                labelText="Rear tyre load & speed rating"
                placeholder="Enter rear tyre load & speed rating"
                requiredStar
                value={formikProps.values.rearTyreLoad}
                onChange={handleInputChange}
                disabled={readOnly}
              />
            </GridColumn>
          </GridRow>
        </Grid>
      </Form>
    </Segment>
  );
};

export default Step1Form;
