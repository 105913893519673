import React from 'react';
import {
  ModalHeader,
  ModalContent,
  ModalActions,
  Button,
  Modal,
  Grid,
  GridColumn,
  GridRow,
  Checkbox,
  Popup,
} from 'semantic-ui-react';

import './modal.scss';
import '../../components/styles.scss';
import { CustomInput } from '../forms/inputs/customInput';
import CustomDateComponent from '../customDateComponent';
import { Formik } from 'formik';
import { confirmVehicleImportValue } from '../../validations/vehicleImportForm/createVehicleImportValidation';

import dayjs from 'dayjs';
import { CustomDropdown } from '../customDropdown';
import { sourceType } from '../../utils';
import ImageUploadComponent from '../fileUpload/imageUploadComponent';
import PdfUploadComponent from '../fileUpload/pdfUploadComponent';
import PdfAndImageUploadComponent from '../fileUpload/pdfAndImageUploadComponent';

const VehicleImportConfirmationModal = ({
  open,
  header,
  values,
  close,
  confirmation,
}) => {
  return (
    <>
      <Modal size="large" open={open} onClose={close}>
        <ModalHeader>{header}</ModalHeader>
        <ModalContent>
          <div className="confirm-vehicle-import-application">
            <div className="update-application">
              <div className="update-application-form-layout">
                <Formik initialValues={confirmVehicleImportValue(values)}>
                  <Grid className="update-application-form" stackable>
                    <GridRow columns={4} style={{ paddingBottom: '0px' }}>
                      <GridColumn width={4}>
                      <CustomInput
                        id="vinNo"
                        name="vinNo"
                        labelText={
                          <Popup
                            trigger={<span>VIN/Chassis Number</span>}
                            content="Vehicle Identification Number"
                          />
                        }
                        disabled={true}
                        requiredStar
                      />
                      </GridColumn>
                      <GridColumn width={4}>
                        <div className="date-of-arrival">
                          <div className="custom-label">
                            <label className="required-stars">
                              Estimated Date of Arrival
                            </label>
                          </div>
                          <div>
                            <div className="date-picker">
                              <CustomDateComponent
                                id="estimatedDate"
                                name="estimatedDate"
                                value={dayjs(values?.estimatedDate) || null}
                                disabled={true}
                              />
                            </div>
                          </div>
                        </div>
                      </GridColumn>
                      <GridColumn width={4}>
                        <CustomInput
                          id="make"
                          name="make"
                          labelText="Make"
                          placeholder="Eg - Ford"
                          disabled={true}
                          requiredStar
                        />
                      </GridColumn>
                      <GridColumn width={4}>
                        <CustomInput
                          id="model"
                          name="model"
                          labelText="Model"
                          placeholder="Eg - Raptor"
                          disabled={true}
                          requiredStar
                        />
                      </GridColumn>
                    </GridRow>
                    <GridRow columns={4} style={{ paddingBottom: '0px' }}>
                      <GridColumn width={4}>
                        <div className="report-type-drop">
                          <label className="required-stars">
                            Power Source Type
                          </label>
                          <div>
                            <CustomDropdown
                              className="custom-drop-down"
                              name="powerSourceType"
                              options={sourceType}
                              placeholder="Choose an option"
                              selection
                              disabled={true}
                            />
                          </div>
                        </div>
                      </GridColumn>
                      <GridColumn width={4}>
                        <CustomInput
                          id="totalNoOfSeats"
                          name="totalNoOfSeats"
                          labelText="Total No of Seats"
                          placeholder="Enter total no of seats"
                          disabled={true}
                          requiredStar
                        />
                      </GridColumn>
                      <GridColumn width={8}>
                        <CustomInput
                          id="notes"
                          name="notes"
                          labelText="Notes"
                          placeholder="Any comments for the reviewer"
                          disabled={true}
                        />
                      </GridColumn>
                    </GridRow>
                    <GridRow
                      columns={1}
                      style={{ paddingBottom: '20px', paddingTop: '30px' }}
                    >
                      <GridColumn
                        width={8}
                        style={{ display: 'flex', alineItems: 'start' }}
                      >
                        <div className="custom-check-box">
                          <Checkbox
                            name="isMoreThan25"
                            label="Is this vehicle older than 25 years"
                            disabled={true}
                            checked={values?.isMoreThan25 || false}
                          />
                        </div>
                      </GridColumn>
                    </GridRow>
                    {values && values.isMoreThan25 ? (
                      <GridRow>
                        <GridColumn width={8}>
                          {/* VIN Plate photos */}
                          <ImageUploadComponent
                            id="vinImages"
                            name="vinImages"
                            images={values?.vinImages || []}
                            label="Vin Photos"
                            smallContainer={true}
                            requiredStar={values?.isMoreThan25 || false}
                            disabled={true}
                          />
                        </GridColumn>
                        <GridColumn width={8}>
                          {/* Engine bay photos */}
                          <ImageUploadComponent
                            id="engineBayImages"
                            name="engineBayImages"
                            images={values?.engineBayImages || []}
                            label="Engine bay photos"
                            smallContainer={true}
                            requiredStar={values?.isMoreThan25 || false}
                            disabled={true}
                          />
                        </GridColumn>
                      </GridRow>
                    ) : null}
                    {/* <InternalVehiclePhotos /> */}
                    <ImageUploadComponent
                      id="interiorImages"
                      name="interiorImages"
                      images={values?.interiorImages || []}
                      label="Internal Vehicle Photos"
                      requiredStar
                      disabled={true}
                    />

                    {/* External vehicle photos */}
                    <ImageUploadComponent
                      id="exteriorImages"
                      name="exteriorImages"
                      images={values?.exteriorImages || []}
                      label="Exterior Vehicle photos"
                      requiredStar
                      disabled={true}
                    />

                    {/* Vehicle Documents */}
                    <GridRow columns={4} style={{ paddingBottom: '0px' }}>
                      <GridColumn width={4}>
                        {/* Export certificate */}
                        <PdfAndImageUploadComponent
                          id="exportCertificate"
                          name="exportCertificate"
                          label="Export certificate"
                          type="export_certificate"
                          document={values?.exportCertificate || null}
                          requiredStar
                          disabled={true}
                        />
                      </GridColumn>
                      <GridColumn width={4}>
                        {/* Auction report */}
                        <PdfAndImageUploadComponent
                          id="auctionReport"
                          name="auctionReport"
                          label="Auction report"
                          type="auction_report"
                          document={values?.auctionReport || null}
                          requiredStar
                          disabled={true}
                        />
                      </GridColumn>
                      <GridColumn width={4}>
                        {/* Vehicle Invoice */}
                        <PdfUploadComponent
                          id="vehicleImport"
                          name="vehicleImport"
                          label="Vehicle invoice"
                          type="vehicle_invoice"
                          document={values?.vehicleImport || null}
                          requiredStar
                          disabled={true}
                          onlyPdf
                        />
                      </GridColumn>
                    </GridRow>
                  </Grid>
                </Formik>
              </div>
            </div>
          </div>
        </ModalContent>
        <ModalActions>
          <Button className="btn btn-primary cancel-button" onClick={close}>
            Cancel
          </Button>

          <Button className="close-button" onClick={confirmation}>
            Confirm
          </Button>
        </ModalActions>
      </Modal>
    </>
  );
};

export default VehicleImportConfirmationModal;
