/*** TYPES ***/

import axios from 'axios';
import { authMsg, getCommonError } from '../../utils';
import { NotificationManager } from 'react-notifications';

const GET_USER_LOADING = 'GET_USER_LOADING';
const GET_USER = 'GET_USER';
const PATCH_USER_LOADING = 'PATCH_USER_LOADING';
const GET_ACCOUNT_INFORMATION = 'GET_ACCOUNT_INFORMATION';
const GET_ACCOUNT_INFORMATION_LOADING = 'GET_ACCOUNT_INFORMATION_LOADING';
const FORGOT_PASSWORD_LOADING='FORGOT_PASSWORD_LOADING';

const UNSUBSCRIBE_LOADING = 'UNSUBSCRIBE_LOADING';
const REACTIVATE_LOADING = 'REACTIVATE_LOADING';

/*** REDUCERS ***/
const initialState = {
  userDataLoading: false,
  user: {},
  userPatchLoading: false,
  unsubscribeLoading: false,
  reactivateLoading: false,
  forgotPasswordLoading:false
};

export default function reducer(state = initialState, action) {
  switch (action.type) {
    case GET_USER_LOADING:
      return { ...state, userDataLoading: action.payload };
    case GET_USER:
      return { ...state, user: action.payload };
    case PATCH_USER_LOADING:
      return { ...state, userPatchLoading: action.payload };
    case GET_ACCOUNT_INFORMATION:
      return { ...state, getAccountInformation: action.payload };
    case GET_ACCOUNT_INFORMATION_LOADING:
      return { ...state, getAccountInformationLoading: action.payload };

    case UNSUBSCRIBE_LOADING:
      return { ...state, unsubscribeLoading: action.payload };

    case REACTIVATE_LOADING:
      return { ...state, reactivateLoading: action.payload };
    case FORGOT_PASSWORD_LOADING:
      return { ...state, forgotPasswordLoading: action.payload };
      
    default:
      return state;
  }
}



export function getAccountInformation(userId) {
  return async (dispatch) => {
    try {
      dispatch({ type: GET_ACCOUNT_INFORMATION_LOADING, payload: true });
      const res = await axios.get(`user/account/${userId}`);
      dispatch({ type: GET_ACCOUNT_INFORMATION, payload: res.data });
      dispatch({ type: GET_ACCOUNT_INFORMATION_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: GET_ACCOUNT_INFORMATION_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function patchUserData(userId, values) {
  return async (dispatch) => {
    try {
      dispatch({ type: PATCH_USER_LOADING, payload: true });
      await axios.patch(`/user/${userId}`, values);
      authMsg('success', ['Updated Successfully!', 'User']);
      dispatch({ type: PATCH_USER_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: PATCH_USER_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function unsubscribeEmailService(values) {
  return async (dispatch) => {
    try {
      dispatch({ type: UNSUBSCRIBE_LOADING, payload: true });
      await axios.post(`/email/unsubscribe`, values);
      authMsg('success', ['Unsubscribed Successfully!', 'Email']);
      dispatch({ type: UNSUBSCRIBE_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: UNSUBSCRIBE_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}

export function reactivateEmailService(values) {
  return async (dispatch) => {
    try {
      dispatch({ type: REACTIVATE_LOADING, payload: true });
      await axios.delete(`/email/subscribe`, values);
      authMsg('success', ['Subscribed Successfully', 'Email']);
      dispatch({ type: REACTIVATE_LOADING, payload: false });
    } catch (error) {
      dispatch({ type: REACTIVATE_LOADING, payload: false });
      authMsg('error', getCommonError(error));
    }
  };
}


export function forgotPasswordUser(email) {
  return async (dispatch) => {
    return new Promise(async (resolve, reject) => {
    try {
      dispatch({ type: FORGOT_PASSWORD_LOADING, payload: true });
      const response=await axios.post(`/auth/forgot-password`, {
        email
      });
      dispatch({ type: FORGOT_PASSWORD_LOADING, payload: false });
      resolve(response.data);
      NotificationManager.success('Email has been sent!', 'Success');
    } catch (error) {
      dispatch({ type: FORGOT_PASSWORD_LOADING, payload: false });
      NotificationManager.error(getCommonError(error));
      // reject(error);
      }
    });
  };
}
