import {
  AUTH_TOKEN,
  IS_AUTHENTICATED,
  removeAuthenticatedToken,
  removeIsAuthenticated,
  removeRefreshToken,
  setIsAuthenticated,
} from './cacheStore';
import { NotificationManager } from 'react-notifications';
import { find, get, isArray } from './lodash';
import moment from 'moment';
import { VEHICLE_IMPORT_ID } from './constant';

export const isCurrentUser = (currentUserId, id) => {
  if (currentUserId === id) return true;
  return false;
};

export const userAuth = {
  isAuthenticated: Boolean(localStorage.getItem(IS_AUTHENTICATED)) || false,
  isAuthToken: Boolean(localStorage.getItem(AUTH_TOKEN)) || false,

  async authenticate(cb) {
    try {
      this.isAuthenticated = true;
      await setIsAuthenticated(true);
    } catch (error) {
      console.error('authenticate -> error', error);
    }
    cb();
  },
  async signout() {
    try {
      this.isAuthenticated = false;
      await removeIsAuthenticated();
      await removeAuthenticatedToken();
      await removeRefreshToken();
    } catch (error) {
      console.error('signout -> error', error);
    }
  },
};

export const authMsg = (type, data) => {
  // if error coming from network issue && need to discard error message (no need to  display error message)
  if (data === 'NETWORK_DISCARD') {
    return false;
  }

  let msg,
    title = null;
  if (isArray(data)) {
    const [message, heading] = data;
    msg = message;
    title = heading;
  } else {
    msg = data;
  }
  if (userAuth.isAuthenticated) {
    switch (type) {
      case 'info':
        NotificationManager.info(msg, title);
        break;
      case 'success':
        NotificationManager.success(msg, title);
        break;
      case 'warning':
        NotificationManager.warning(msg, title);
        break;
      case 'error':
        NotificationManager.error(msg, title);
        break;
      default:
        break;
    }
  } else {
    return false;
  }
};

export const getApplicationType = (type) => {
  switch (type) {
    case 1:
      return 'Vehicle Import';
    case 2:
      return 'Compliance';
    default:
      break;
  }
};

export const getUniqueVehicleNo = (type) => {
  switch (type) {
    case 1:
      return 'VIN';
    case 2:
      return 'CHASSIS NO';
    default:
      break;
  }
};

export const getApplicationTypeInitial = (type) => {
  switch (type) {
    case 1:
      return 'Vi';
    case 2:
      return 'C';
    default:
      break;
  }
};

export const generateApplicationId = (id, type) => {
  const pre = getApplicationTypeInitial(type);
  return `${pre}_${generateAppIdInThousands(id)}`;
};

export const generateAppIdInThousands = (number) => {
  return String(number).padStart(4, '0');
};

export const getDates = (value) => {
  const today = moment();
  let startDate, endDate;

  switch (value) {
    case 'thisWeek':
      startDate = moment(today).startOf('isoWeek');
      endDate = moment(today).endOf('isoWeek');
      break;
    case 'lastMonth':
      startDate = moment(today).subtract(1, 'month').startOf('month');
      endDate = moment(today).subtract(1, 'month').endOf('month');
      break;
    case 'today':
      startDate = moment(today).startOf('day');
      endDate = moment(today).endOf('day');
      break;
    default:
      // Handle unsupported values or provide a default behavior
      throw new Error('Unsupported value for date range');
  }

  const formattedStartDate = startDate.format('YYYY-MM-DD HH:mm:ss');
  const formattedEndDate = endDate.format('YYYY-MM-DD HH:mm:ss');

  return { formattedStartDate, formattedEndDate };
};

export const getCommonError = (errorResponse) => {
  try {
    // show network error (not discard network error)
    if (errorResponse === 'NETWORK_ERROR') {
      return 'Network Connection Error!';
    }

    // network error discard (to prevent show network error message)
    if (errorResponse === 'NETWORK_DISCARD') {
      return 'NETWORK_DISCARD';
    }

    const errorData = errorResponse.response;

    if (!errorData) {
      return 'Oops, Something went wrong!';
    }

    if (errorData.data.message) {
      return errorData.data.message;
    }

    if (errorData.status) {
      if (errorData.status === 400) {
        return 'Your entered value is not valid or required, Please check again!';
      } else if (errorData.status === 401) {
        return [
          "You were idle too long. You'll need to login again.",
          "You've Timed Out!",
        ];
      } else if (errorData.status === 403) {
        return (
          errorData.data.message ||
          "You don't have permission to make this call"
        );
      } else if (errorData.status === 404) {
        return 'Sorry, Record not found';
      } else if (errorData.status === 405) {
        return 'Request is not allowed';
      } else if (errorData.status === 500) {
        return 'Oops, Something went wrong!';
      } else {
        return 'Oops, Something went wrong!';
      }
    }

    return 'Oops, Something went wrong!';
  } catch (error) {
    console.error('getCommonError -> error', error);
    return 'Oops, Something went wrong!';
  }
};

export const getIdInPath = (pathname) => {
  const segments = pathname.split('/');
  const lastSegment = segments[segments.length - 1];

  return lastSegment;
};

export const createWithFormData = (formData, formDataValues) => {
  for (const [key, value] of Object.entries(formDataValues)) {
    formData.set(key, value);
  }

  return formData;
};

export const sourceType = [
  {
    key: 'Hybrid',
    value: 'Hybrid',
    text: 'Hybrid',
  },
  {
    key: 'Electric',
    value: 'Electric',
    text: 'Electric',
  },
  {
    key: 'petrol',
    value: 'petrol',
    text: 'Petrol',
  },
  {
    key: 'Diesel',
    value: 'Diesel',
    text: 'Diesel',
  },
];

export const transmissionTypeOptions = [
  {
    key: 'automatic',
    value: 'Automatic',
    text: 'Automatic',
  },
  {
    key: 'manual',
    value: 'manual',
    text: 'Manual',
  },
];

export const getChassisVinNo = (typeId, responses) => {
  const attributeId = typeId === VEHICLE_IMPORT_ID ? 1 : 16;
  const getValue = find(responses, (item) => item.attributeId === attributeId);
  return get(getValue, 'attributeValue', '');
};

export const termsConditions = () => {
  return (
    <ul>
      <li>
        {' '}
        Only vehicles meeting the specified criteria set by the importing
        country's regulations are eligible for import.
      </li>
      <li>
        {' '}
        Importers must provide accurate and complete documentation for the
        imported vehicle, including but not limited to the original title, bill
        of sale, import permit (if required), and any relevant certificates of
        compliance.
      </li>
      <li>
        Importers are responsible for paying all applicable customs duties,
        taxes, and fees associated with importing the vehicle.
      </li>
    </ul>
  );
};

export const getMsg = () => {
  const currentTime = new Date();
  const currentHour = currentTime.getHours();
  if (currentHour < 12) {
    return 'Good Morning';
  } else if (currentHour >= 12 && currentHour < 18) {
    return 'Good Afternoon';
  } else {
    return 'Good Evening';
  }
};

export const STATUS = {
  DRAFTED: 'DRAFTED',
  SUBMITTED: 'SUBMITTED',
};
