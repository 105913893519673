import React from 'react';
import { Grid, GridColumn, GridRow, Segment, Form } from 'semantic-ui-react';
import FileUploadComponent from '../../../components/fileUpload/fileUploadComponent';
import { CustomInput } from '../../../components/forms/inputs/customInput';
import CustomDateComponent from '../../../components/customDateComponent';
import MakeSelection from '../common/makeSelection';
import ModelSelection from '../common/modelSelection';
import { CustomDropdown } from '../../../components/customDropdown';
import { transmissionTypeOptions } from '../../../utils';

const ReviewApplicationForm = ({ formValues, vehicleArrivedDate, make }) => {
  return (
    <Segment className="update-application-form-layout" attached>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-between',
          paddingBottom: '1rem',
        }}
      >
        <h2 style={{ paddingLeft: '15px', paddingTop: '15px' }}>
          Review Compliance Application
        </h2>
        <h4 style={{ paddingRight: '15px' }}>Review All Steps</h4>
      </div>
      <hr />
      <Form>
        <Grid className="update-application-form" stackable>
          {/* Step 1 Fields */}
          <GridRow columns={4}>
            <GridColumn width={4}>
              <CustomInput
                id="chassisNo"
                name="chassisNo"
                labelText="Chassis Number"
                placeholder="Enter chassis number"
                requiredStar
                value={formValues.chassisNo}
                readOnly
              />
            </GridColumn>
            <GridColumn width={4}>
              <div className="date-of-arrival">
                <label className="required-stars">
                  Date your vehicle arrived in Australia
                </label>
                <CustomDateComponent
                  value={vehicleArrivedDate}
                  name="vehicleArrivedDate"
                  id="vehicleArrivedDate"
                  placeholder="yyyy-mm-dd"
                  readOnly
                />
              </div>
            </GridColumn>
            <GridColumn width={4}>
              <CustomInput
                id="engineNo"
                name="engineNo"
                labelText="Engine number"
                placeholder="Enter your vehicle’s engine number"
                requiredStar
                value={formValues.engineNo}
                readOnly
              />
            </GridColumn>
            <GridColumn width={4}>
              <label className="required-stars">Transmission Type</label>
              <CustomDropdown
                className="custom-drop-down"
                name="transmissionType"
                options={transmissionTypeOptions}
                placeholder="Select"
                value={formValues.transmissionType}
                readOnly
              />
            </GridColumn>
          </GridRow>

          <GridRow columns={4}>
            <GridColumn width={4}>
              <MakeSelection
                id="make"
                label="Make"
                placeholder="Eg - Ford"
                handleMakeChange={() => {}}
                value={make}
                readOnly
              />
            </GridColumn>
            <GridColumn width={4}>
              <ModelSelection
                id="model"
                label="Model"
                placeholder="Eg - Raptor"
                selectedMake={make}
                value={formValues.model}
                readOnly
              />
            </GridColumn>
            <GridColumn width={4}>
              <CustomInput
                id="odometerReading"
                name="odometerReading"
                labelText="Odometer reading"
                placeholder="Current Odometer reading"
                requiredStar
                value={formValues.odometerReading}
                readOnly
              />
            </GridColumn>
            <GridColumn width={4}>
              <CustomInput
                id="noOfDoors"
                name="noOfDoors"
                labelText="Number of doors (Including gate/rear boot)"
                placeholder="Enter your vehicle’s door count"
                requiredStar
                value={formValues.noOfDoors}
                readOnly
              />
            </GridColumn>
          </GridRow>

          <GridRow columns={2}>
            <GridColumn width={4}>
              <CustomInput
                id="totalNoOfSeats"
                name="totalNoOfSeats"
                labelText="Total No of Seats"
                placeholder="Enter total no of seats"
                requiredStar
                value={formValues.totalNoOfSeats}
                readOnly
              />
            </GridColumn>
            <GridColumn width={4}>
              <CustomInput
                id="notes"
                name="notes"
                labelText="Notes"
                placeholder="Any comments for the reviewer"
                value={formValues.notes}
                readOnly
              />
            </GridColumn>
          </GridRow>

          <GridRow columns={1}>
            <GridColumn width={16}>
              <div className="sub-topic">
                <label>Enter Factory Tyre placard Specs details below</label>
              </div>
            </GridColumn>
          </GridRow>

          <GridRow columns={4}>
            <GridColumn width={4}>
              <CustomInput
                id="frontTyreSize"
                name="frontTyreSize"
                labelText="Front tyre size"
                placeholder="Enter front tyre size"
                requiredStar
                value={formValues.frontTyreSize}
                readOnly
              />
            </GridColumn>
            <GridColumn width={4}>
              <CustomInput
                id="frontTyreLoad"
                name="frontTyreLoad"
                labelText="Front tyre load & speed rating"
                placeholder="Enter front tyre load & speed rating"
                requiredStar
                value={formValues.frontTyreLoad}
                readOnly
              />
            </GridColumn>
            <GridColumn width={4}>
              <CustomInput
                id="rearTyreSize"
                name="rearTyreSize"
                labelText="Rear tyre size"
                placeholder="Enter rear tyre size"
                requiredStar
                value={formValues.rearTyreSize}
                readOnly
              />
            </GridColumn>
            <GridColumn width={4}>
              <CustomInput
                id="rearTyreLoad"
                name="rearTyreLoad"
                labelText="Rear tyre load & speed rating"
                placeholder="Enter rear tyre load & speed rating"
                requiredStar
                value={formValues.rearTyreLoad}
                readOnly
              />
            </GridColumn>
          </GridRow>

          {/* Step 2 Fields */}
          <GridRow columns={1}>
            <GridColumn width={16}>
              <h4>Existing Vehicle Import Approval</h4>
              <p>(Only post if obtained outside of Import Revolution)</p>
            </GridColumn>
          </GridRow>
          <GridRow columns={1}>
            <GridColumn width={4}>
              <FileUploadComponent
                name="approvalDocument"
                label="Existing Vehicle import approval"
                document={formValues.approvalDocument}
                readOnly
              />
            </GridColumn>
          </GridRow>

          <GridRow columns={4}>
            <GridColumn width={4}>
              <FileUploadComponent
                name="engravedVin"
                label="Engraved VIN plate"
                document={formValues.engravedVin}
                readOnly
              />
            </GridColumn>
            <GridColumn width={4}>
              <FileUploadComponent
                name="passengerSideAirbag"
                label="Passenger side airbag sticker"
                document={formValues.passengerSideAirbag}
                readOnly
              />
            </GridColumn>
            <GridColumn width={4}>
              <FileUploadComponent
                name="compliancePlate"
                label="Compliance Plate"
                document={formValues.compliancePlate}
                readOnly
              />
            </GridColumn>
            <GridColumn width={4}>
              <FileUploadComponent
                name="australianRoadPlate"
                label="Australian Road Plate"
                document={formValues.australianRoadPlate}
                readOnly
              />
            </GridColumn>
          </GridRow>

          <GridRow columns={4}>
            <GridColumn width={4}>
              <FileUploadComponent
                name="tyreOne"
                label="Tyre Code & Tread - Tyre 1"
                document={formValues.tyreOne}
                readOnly
              />
            </GridColumn>
            <GridColumn width={4}>
              <FileUploadComponent
                name="tyreTwo"
                label="Tyre Code & Tread - Tyre 2"
                document={formValues.tyreTwo}
                readOnly
              />
            </GridColumn>
            <GridColumn width={4}>
              <FileUploadComponent
                name="tyreThree"
                label="Tyre Code & Tread - Tyre 3"
                document={formValues.tyreThree}
                readOnly
              />
            </GridColumn>
            <GridColumn width={4}>
              <FileUploadComponent
                name="tyreFour"
                label="Tyre Code & Tread - Tyre 4"
                document={formValues.tyreFour}
                readOnly
              />
            </GridColumn>
          </GridRow>

          <GridRow columns={4}>
            <GridColumn width={4}>
              <FileUploadComponent
                name="petrolOrDiesel"
                label="Unleaded Petrol or Diesel Sticker"
                document={formValues.petrolOrDiesel}
                readOnly
              />
            </GridColumn>
            <GridColumn width={4}>
              <FileUploadComponent
                name="odometerReadingUpload"
                label="Odometer Reading"
                document={formValues.odometerReadingUpload}
                readOnly
              />
            </GridColumn>
            <GridColumn width={4}>
              <FileUploadComponent
                name="undercarriageFront"
                label="Undercarriage - Front"
                document={formValues.undercarriageFront}
                readOnly
              />
            </GridColumn>
            <GridColumn width={4}>
              <FileUploadComponent
                name="undercarriageRear"
                label="Undercarriage - Rear"
                document={formValues.undercarriageRear}
                readOnly
              />
            </GridColumn>
          </GridRow>

          <GridRow columns={4}>
            <GridColumn width={4}>
              <FileUploadComponent
                name="undercarriageLeft"
                label="Undercarriage - Left"
                document={formValues.undercarriageLeft}
                readOnly
              />
            </GridColumn>
            <GridColumn width={4}>
              <FileUploadComponent
                name="undercarriageRight"
                label="Undercarriage - Right"
                document={formValues.undercarriageRight}
                readOnly
              />
            </GridColumn>
            <GridColumn width={4}>
              <FileUploadComponent
                name="undercarriageCenter"
                label="Undercarriage - Center"
                document={formValues.undercarriageCenter}
                readOnly
              />
            </GridColumn>
          </GridRow>

          {/* Optional Pictures */}
          <GridRow columns={1}>
            <GridColumn width={16}>
              <h4>Optional Pictures</h4>
            </GridColumn>
          </GridRow>

          <GridRow columns={3}>
            <GridColumn width={4}>
              <FileUploadComponent
                name="childAnchorPoints"
                label="Child Anchorage Points"
                document={formValues.childAnchorPoints}
                readOnly
              />
            </GridColumn>
            <GridColumn width={4}>
              <FileUploadComponent
                name="headlights"
                label="HID to Halogen Converted Headlights"
                document={formValues.headlights}
                readOnly
              />
            </GridColumn>
            <GridColumn width={4}>
              <FileUploadComponent
                name="towConnector"
                label="New CSA/Tow Connector"
                document={formValues.towConnector}
                readOnly
              />
            </GridColumn>
          </GridRow>
        </Grid>
      </Form>
    </Segment>
  );
};

export default ReviewApplicationForm;
