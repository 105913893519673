import { v4 as uuidv4 } from 'uuid';

export const IS_AUTHENTICATED = 'IS_AUTHENTICATED';
export const AUTH_TOKEN = 'AUTH_TOKEN';
export const REFRESH_TOKEN = 'REFRESH_TOKEN';
export const ROLE_ID = 'ROLE_ID';

const ACTIVITIES_STEPS = 'ACTIVITIES_STEPS';
const ACTIVITIES_FORM_STEPS = 'ACTIVITIES_FORM_STEPS';
const DASHBOARD_STEPS = 'DASHBOARD_STEPS';

export async function removePersist() {
  return localStorage.removeItem('persist:root');
}

export async function isAuthenticated() {
  return localStorage.getItem(IS_AUTHENTICATED);
}

export async function setIsAuthenticated(value) {
  return localStorage.setItem(IS_AUTHENTICATED, value);
}

export async function removeIsAuthenticated() {
  return localStorage.removeItem(IS_AUTHENTICATED);
}

export async function getAuthenticatedToken() {
  return localStorage.getItem(AUTH_TOKEN);
}

export async function setAuthenticatedToken(value) {
  return localStorage.setItem(AUTH_TOKEN, value);
}

export async function setRoleID(value) {
  return localStorage.setItem(ROLE_ID, value);
}

export async function removeAuthenticatedToken() {
  return localStorage.removeItem(AUTH_TOKEN);
}

export async function getRefreshToken() {
  return localStorage.getItem(REFRESH_TOKEN);
}

export async function setRefreshToken(value) {
  return localStorage.setItem(REFRESH_TOKEN, value);
}

export async function removeRefreshToken() {
  return localStorage.removeItem(REFRESH_TOKEN);
}

export function getCachedActivitiesSteps() {
  return localStorage.getItem(ACTIVITIES_STEPS);
}

export function setCachedActivitiesSteps(cachedActivitiesSteps) {
  return localStorage.setItem(ACTIVITIES_STEPS, cachedActivitiesSteps);
}

export function removeCachedActivitiesSteps() {
  return localStorage.removeItem(ACTIVITIES_STEPS);
}

export async function getCachedDashboardSteps() {
  return localStorage.getItem(DASHBOARD_STEPS);
}

export async function setCachedDashboardSteps(cachedDashboardSteps) {
  return localStorage.setItem(DASHBOARD_STEPS, cachedDashboardSteps);
}

export async function removeCachedDashboardSteps(cachedDashboardSteps) {
  return localStorage.removeItem(DASHBOARD_STEPS);
}

export async function getCachedActivitiesFormSteps() {
  return localStorage.getItem(ACTIVITIES_FORM_STEPS);
}

export async function setCachedActivitiesFormSteps(cachedActivitiesSteps) {
  return localStorage.setItem(ACTIVITIES_FORM_STEPS, cachedActivitiesSteps);
}

export async function removeCachedActivitiesFormSteps(cachedActivitiesSteps) {
  return localStorage.removeItem(ACTIVITIES_FORM_STEPS);
}

export async function getCachedSurveyToken(token) {
  return localStorage.getItem(token);
}

export async function setCachedSurveyToken(token) {
  return localStorage.setItem(`${token}`, uuidv4());
}

/* -------------------- Begin Network Local Storage --------------------------- */
export async function getPreviousNetworkStatus() {
  return localStorage.getItem('previousNetworkStatus');
}

export async function setPreviousNetworkStatus(status) {
  return localStorage.setItem(`previousNetworkStatus`, status);
}
/* -------------------- End Network Local Storage --------------------------- */
