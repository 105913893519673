import { React } from 'react';
import { Container, Grid } from 'semantic-ui-react';
import { CustomInput } from '../../../components/forms/inputs/customInput';
import '../sideBar.scss';

export const UpdateCustomer = () => {
  return (
    <Grid.Row className={'create-customer-page-wrapper'}>
    <Container className="right-heading-container">
      <div className="customer-content">
        <CustomInput
          id="name"
          requiredStar
          name="name"
          placeholder="Name"
          labelText="Name"
          type="text"
        />
        <CustomInput
          id="email"
          name="email"
          placeholder="Email"
          labelText="Email"
          type="text"
          requiredStar
        />
        <CustomInput
          id="contactNo"
          name="contactNo"
          placeholder="Contact Number"
          labelText="Contact Number"
          requiredStar
        />
        <CustomInput
          id="businessName"
          name="businessName"
          placeholder="Business Name"
          labelText="Business Name (Optional)"
          type="text"
        />
        <CustomInput
          id="abn"
          name="abn"
          placeholder="ABN"
          labelText="ABN"
          type="text"
        />
       </div>
      </Container>
    </Grid.Row>
    
  );
};

export default UpdateCustomer;
