import React, { useEffect, useMemo, useState } from 'react';
import { Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import {
  Button,
  Grid,
  GridColumn,
  GridRow,
  Header,
  Icon,
  Segment,
} from 'semantic-ui-react';
import dayjs from 'dayjs';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { isMobile } from 'react-device-detect';

import { ADMIN_ROLE, COMPLIANCE_ID, STATUS } from '../../../utils/constant';
import { get, isEmpty } from '../../../utils/lodash';
import { FooterAdmin, FooterCustomer } from '../common/footer';
import Step1Form from './Step1Form';
import Step2Form from './Step2Form';
import { CustomComment } from '../../../components/customComment';
import CustomTextAreaContainer from '../../../components/CustomContainers/customTextAreaContainer';
import {
  complianceValidationSchemaStep1,
  updateComplianceValidationSchema,
} from '../../../validations/complianceForm/complianceValidation';
import {
  downloadAllTheFiles,
  patchApplicationActiveStatus,
  patchComplianceApplication,
} from '../../../store/modules/applications';
import {
  postApplicationComments,
  patchApplicationComment,
} from '../../../store/modules/comments';

const UpdateComplianceApplication = ({ selectedApp = '' }) => {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [step, setStep] = useState(1);
  const [commentText, setCommentText] = useState('');
  const [initialValues, setInitialValues] = useState({});
  const [applicationStatus, setApplicationStatus] = useState(
    selectedApp.status
  );
  const [isDownloading, setDownloading] = useState(false);

  const user = useSelector((state) => state.auth.user);

  const patchCallObj = useSelector(
    (state) => state.applications.applicationPatch
  );
  const downloadLoading = useSelector(
    (state) => state.applications.downloadLoading
  );
  const removeLoading = useSelector(
    (state) => state.applications.disableApplicationLoading
  );
  const addCommentLoading = useSelector(
    (state) => state.comments.addCommentLoading
  );
  const updateCommentLoading = useSelector(
    (state) => state.comments.updateCommentLoading
  );

  const loading = useMemo(() => {
    return (
      patchCallObj.vehicleImportStatus ||
      patchCallObj.complianceStatus ||
      downloadLoading ||
      removeLoading
    );
  }, [patchCallObj, downloadLoading, removeLoading]);

  // Load from session storage if available
  useEffect(() => {
    const cachedData = sessionStorage.getItem('complianceForm');
    if (cachedData) {
      try {
        const parsedData = JSON.parse(cachedData);
        setInitialValues({
          ...parsedData,
        });
        setApplicationStatus(parsedData.status || selectedApp.status);
      } catch (error) {
        console.error('Error parsing sessionStorage data:', error);
      }
    } else {
      setInitialValues(
        updateComplianceValidationSchema(
          selectedApp.formResponses,
          selectedApp.commentsData
        )
      );
    }
  }, [
    selectedApp.commentsData,
    selectedApp.formResponses,
    selectedApp.status,
    step,
  ]);

  // Save to session storage whenever initialValues change
  useEffect(() => {
    if (!isEmpty(initialValues)) {
      sessionStorage.setItem('complianceForm', JSON.stringify(initialValues));
    }
  }, [initialValues]);

  // Remove data from sessionStorage on unmount
  useEffect(() => {
    return () => {
      sessionStorage.removeItem('complianceForm');
    };
  }, []);

  if (isEmpty(initialValues)) return null;

  const handleRemoveClick = () => {
    if (selectedApp.status !== STATUS.DRAFTED) {
      dispatch(patchApplicationActiveStatus(selectedApp.id));
    }
  };

  const onClickStatusUpdate = async (status) => {
    const data = { status };
    await dispatch(
      patchComplianceApplication(selectedApp.id, data, COMPLIANCE_ID)
    );
    setApplicationStatus(status);
  };

  const onDownloadAllFiles = () => {
    if (!isDownloading) {
      setDownloading(true);
      dispatch(downloadAllTheFiles(selectedApp.id, selectedApp.formTypeId));
      setTimeout(() => setDownloading(false), 2000);
    }
  };

  const onSaveComment = () => {
    const data = {
      userId: user.id,
      applicationId: selectedApp.id,
      commentText: commentText,
    };
    if (isEmpty(selectedApp.commentsData)) {
      dispatch(postApplicationComments(data));
    } else {
      const commentId = selectedApp.commentsData[0].id;
      if (commentId) {
        dispatch(patchApplicationComment(commentId, data));
      }
    }
  };

  const onBlur = (e) => {
    setCommentText(e.target.value);
  };

  const renderStep = (values) => {
    if (step === 1) {
      return (
        <Step1Form
          vehicleArrivedDate={dayjs(values.vehicleArrivedDate)}
          make={values.make}
          formikProps={{ values }}
          handleMakeChange={() => {}}
          onDateChange={() => {}}
          readOnly
          updateData
        />
      );
    }
    if (step === 2) {
      return (
        <Step2Form
          uploadValues={initialValues}
          handleFileUpload={() => {}}
          handleDocumentDelete={() => {}}
          readOnly
          updateData
        />
      );
    }
    return null;
  };

  return (
    <div className="update-application">
      {/* Header Section */}
      <Header className="update-application-header" attached="top">
        <Grid stackable>
          <GridRow columns={2}>
            <GridColumn>
              <Grid>
                <GridRow>
                  <GridColumn
                    className="update-application-title"
                    mobile={16}
                    tablet={14}
                    computer={10}
                    largeScreen={10}
                    widescreen={10}
                  >
                    {isMobile && (
                      <Icon name="arrow left" onClick={() => navigate(-1)} />
                    )}
                    Updating Application {get(selectedApp, 'referenceNo', '')}
                  </GridColumn>
                  <GridColumn
                    mobile={16}
                    tablet={2}
                    computer={8}
                    largeScreen={6}
                    widescreen={6}
                  >
                    <Button
                      className={
                        applicationStatus === 'LODGED'
                          ? 'lodged-button'
                          : 'submitted-button'
                      }
                    >
                      {applicationStatus}
                    </Button>
                  </GridColumn>
                </GridRow>
              </Grid>
            </GridColumn>
          </GridRow>
        </Grid>
      </Header>
      <Formik
        initialValues={initialValues}
        validationSchema={complianceValidationSchemaStep1}
      >
        {({ values }) => (
          <Form>
            <Segment
              className="update-application-form-layout"
              attached
              loading={loading}
            >
              <Grid className="update-application-form">
                {renderStep(values)}
              </Grid>
            </Segment>

            {/* Navigation Buttons */}
            <Segment className="update-application-footer" attached="bottom">
              <Grid>
                <GridRow columns={2}>
                  <GridColumn>
                    {step > 1 && (
                      <Button onClick={() => setStep(step - 1)} secondary>
                        Previous
                      </Button>
                    )}
                  </GridColumn>
                  <GridColumn textAlign="right">
                    {step < 2 ? (
                      <Button onClick={() => setStep(step + 1)} secondary>
                        Next
                      </Button>
                    ) : null}
                  </GridColumn>
                </GridRow>
              </Grid>
            </Segment>

            {/* Comment Panel */}
            {user.roleId === ADMIN_ROLE && (
              <Segment className="comments-section">
                <Grid>
                  <GridRow>
                    <GridColumn width={16}>
                      <CustomTextAreaContainer>
                        <CustomComment
                          id="comments"
                          name="comments"
                          onBlur={onBlur}
                        />
                      </CustomTextAreaContainer>
                    </GridColumn>
                  </GridRow>
                  <GridRow>
                    <GridColumn floated="right" width={3}>
                      <Button
                        className="save-button"
                        onClick={onSaveComment}
                        loading={addCommentLoading || updateCommentLoading}
                        disabled={addCommentLoading || updateCommentLoading}
                      >
                        Save Comment
                      </Button>
                    </GridColumn>
                  </GridRow>
                </Grid>
              </Segment>
            )}

            {/* Footer Section */}
            <Segment
              className="update-application-footer"
              attached="bottom"
              disabled={loading}
            >
              {get(user, 'roleId', '') === ADMIN_ROLE ? (
                <FooterAdmin
                  user={user}
                  selectedApp={selectedApp}
                  handleRemoveClick={handleRemoveClick}
                  removeLoading={removeLoading}
                  onDownloadAllFiles={onDownloadAllFiles}
                  isDownloading={isDownloading}
                  onClickStatusUpdate={onClickStatusUpdate}
                />
              ) : (
                <FooterCustomer
                  onDownloadAllFiles={onDownloadAllFiles}
                  isDownloading={isDownloading}
                />
              )}
            </Segment>
          </Form>
        )}
      </Formik>
    </div>
  );
};

UpdateComplianceApplication.propTypes = {
  selectedApp: PropTypes.object.isRequired,
};

export default UpdateComplianceApplication;
