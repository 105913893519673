import { Field, Form, Formik } from 'formik';
import PropTypes from 'prop-types';
import React, { useEffect, useMemo, useRef, useState } from 'react';
import {
  Button,
  Checkbox,
  Divider,
  Grid,
  GridColumn,
  GridRow,
  Header,
  Icon,
  Popup,
  Segment,
} from 'semantic-ui-react';
import { CustomInput } from '../../../components/forms/inputs/customInput';

import PdfUploadComponent from '../../../components/fileUpload/pdfUploadComponent';
import { useDispatch, useSelector } from 'react-redux';
import { sourceType } from '../../../utils';
import ImageUploadComponent from '../../../components/fileUpload/imageUploadComponent';

import {
  downloadAllTheFiles,
  patchApplicationActiveStatus,
  patchVehicleImport,
} from '../../../store/modules/applications';
import { find, get, isEmpty } from '../../../utils/lodash';
import { VehicleDocumentTypeIds } from '../../../utils/vehicleImportConstant';

import { CustomDropdown } from '../../../components/customDropdown';
import { ADMIN_ROLE, STATUS, VEHICLE_IMPORT_ID } from '../../../utils/constant';
import {
  VehicleImportValidationSchema,
  updateVehicleImportValidationSchema,
} from '../../../validations/vehicleImportForm/createVehicleImportValidation';
import CustomDateComponent from '../../../components/customDateComponent';
import dayjs from 'dayjs';
import { FooterAdmin, FooterCustomer } from '../common/footer';

import { CustomComment } from '../../../components/customComment';
import {
  postApplicationComments,
  patchApplicationComment,
} from '../../../store/modules/comments';

import { useNavigate } from 'react-router-dom';
import PdfAndImageUploadComponent from '../../../components/fileUpload/pdfAndImageUploadComponent';
import { isMobile } from 'react-device-detect';
import CustomTextAreaContainer from '../../../components/CustomContainers/customTextAreaContainer';

const UpdateVehicleImportApplication = ({ selectedApp = '' }) => {
  const [isEdit, setIsEdit] = useState(false);
  const [commentText, setCommentText] = useState('');
  const [ebVehicleImages, setEbVehicleImages] = useState([]);
  const [vinPlateImages, setVinPlateImages] = useState([]);
  const [inVehicleImages, setInVehicleImages] = useState([]);
  const [exVehicleImages, setExVehicleImages] = useState([]);

  const inVehicleFileInputRef = useRef(null);
  const exVehicleFileInputRef = useRef(null);
  const ebVehicleFileInputRef = useRef(null);
  const vinPlateFileInputRef = useRef(null);

  const uploadExportCertificateInputRef = useRef(null);
  const uploadAuctionInputRef = useRef(null);
  const uploadVehicleInvoiceInputRef = useRef(null);

  const [exportCertificate, setExportCertificate] = useState(null);
  const [auctionFile, setAuctionFile] = useState(null);
  const [vehicleInvoice, setVehicleInvoice] = useState(null);

  const [applicationStatus, setApplicationStatus] = useState(
    selectedApp.status
  );

  const user = useSelector((state) => state.auth.user);

  const dispatch = useDispatch();
  const handleFileInputChange = (e, updateStateName, label) => {
    const files = e.target.files;
    const newImages = [];
    for (let i = 0; i < files.length; i++) {
      const file = files[i];
      newImages.push({ file });
      if (label === 'engineBay') {
        updateStateName([...ebVehicleImages, ...newImages]);
      } else if (label === 'vinPhotos') {
        updateStateName([...vinPlateImages, ...newImages]);
      } else if (label === 'interiorImage') {
        updateStateName([...inVehicleImages, ...newImages]);
      } else if (label === 'exteriorImage') {
        updateStateName([...exVehicleImages, ...newImages]);
      }
    }
  };

  const handleFileUpload = (event, setStateData) => {
    const file = event.target.files[0];
    if (file) {
      setStateData({ file });
    }
  };

  const handleDocumentDelete = (updateState, inputRef) => {
    updateState(null);
    if (inputRef.current) {
      inputRef.current.value = null; // Clear the file input
    }
  };
  const onSubmit = async (values) => {
    try {
    } catch (error) {
      console.error('Form is not submitted');
    }
  };

  const [, setEstimatedDate] = useState(null);
  const onDateChange = (value) => {
    setEstimatedDate(value);
  };

  const [initialValues, setInitialValues] = useState({});
  const [isDownloading, setDownloading] = useState(false);

  useEffect(() => {
    const values = updateVehicleImportValidationSchema(
      selectedApp.formResponses,
      selectedApp.commentsData
    );
    setInitialValues(values);

    const certificate = find(
      selectedApp.formResponses,
      (item) => item.attributeId === VehicleDocumentTypeIds.exportCertificate
    );

    const auction = find(
      selectedApp.formResponses,
      (item) => item.attributeId === VehicleDocumentTypeIds.auctionReport
      );


    const invoice = find(
      selectedApp.formResponses,
      (item) => item.attributeId === VehicleDocumentTypeIds.vehicleImport
    );

    setExportCertificate({
      file: { url: get(certificate, 'multipleValues[0].url', '') },
    });
    setAuctionFile({
      file: { url: get(auction, 'multipleValues[0].url', '') },
    });
    setVehicleInvoice({
      file: { url: get(invoice, 'multipleValues[0].url', '') },
    });

    const exteriorImages = find(
      selectedApp.formResponses,
      (item) => item.attributeId === VehicleDocumentTypeIds.exteriorImages
    );
    const interiorImages = find(
      selectedApp.formResponses,
      (item) => item.attributeId === VehicleDocumentTypeIds.interiorImages
    );

    setExVehicleImages(get(exteriorImages, 'multipleValues', ''));
    setInVehicleImages(get(interiorImages, 'multipleValues', ''));

    if (selectedApp.isVehicleOld) {
      const vinImages = find(
        selectedApp.formResponses,
        (item) => item.attributeId === VehicleDocumentTypeIds.vinImages
      );
      const engineBayImages = find(
        selectedApp.formResponses,
        (item) => item.attributeId === VehicleDocumentTypeIds.engineBayImages
      );
      setVinPlateImages(get(vinImages, 'multipleValues', ''));
      setEbVehicleImages(get(engineBayImages, 'multipleValues', ''));
    }

    setIsEdit(selectedApp.status === STATUS.DRAFTED);

    setApplicationStatus(selectedApp.status);
  }, [
    selectedApp.formTypeId,
    selectedApp.formResponses,
    selectedApp.isVehicleOld,
    selectedApp.status,
    selectedApp.commentsData,
  ]);

  const handleRemoveClick = async () => {
    if (selectedApp.status !== STATUS.DRAFTED) {
      await dispatch(patchApplicationActiveStatus(selectedApp.id));
    }
  };

  const patchCallObj = useSelector(
    (state) => state.applications.applicationPatch
  );
  const downloadLoading = useSelector(
    (state) => state.applications.downloadLoading
  );
  const removeLoading = useSelector(
    (state) => state.applications.disableApplicationLoading
  );
  const loading = useMemo(() => {
    return patchCallObj.vehicleImportStatus || downloadLoading || removeLoading;
  }, [patchCallObj.vehicleImportStatus, downloadLoading, removeLoading]);

  const onClickStatusUpdate = async (status) => {
    const data = {
      status,
    };
    await dispatch(patchVehicleImport(selectedApp.id, data, VEHICLE_IMPORT_ID));
    setApplicationStatus(status);
  };

  const onBlur = (e) => {
    setCommentText(e.target.value);
  };

  const onSaveComment = () => {
    const data = {
      userId: user.id,
      applicationId: selectedApp.id,
      commentText: commentText,
    };
    if (isEmpty(selectedApp.commentsData)) {
      dispatch(postApplicationComments(data));
    } else {
      const commentId = selectedApp.commentsData[0].id;
      if (commentId) {
        dispatch(patchApplicationComment(commentId, data));
      }
    }
  };

  const onDownloadAllFiles = async () => {
    if (!isDownloading) {
      setDownloading(true);
      await dispatch(
        downloadAllTheFiles(selectedApp.id, selectedApp.formTypeId)
      );
      setTimeout(() => {
        setDownloading(false); // Enable the button after download is complete
      }, 100);
    }
  };
  const navigate = useNavigate();
  if (isEmpty(initialValues)) return;

  return (
    <div className="update-application">
      {/* Header Section */}
      <Header className="update-application-header" attached="top">
        <Grid>
          <GridRow>
            <GridColumn
              className="update-application-title"
              mobile={16}
              tablet={11}
              computer={10}
              largeScreen={6}
              widescreen={6}
            >
              {isMobile ? (
                <Icon name="arrow left" onClick={() => navigate(-1)} />
              ) : null}
              Updating Application {get(selectedApp, 'referenceNo', '')}
            </GridColumn>
            <GridColumn
              mobile={16}
              tablet={6}
              computer={8}
              largeScreen={10}
              widescreen={10}
            >
              <Button
                className={
                  applicationStatus === 'LODGED'
                    ? 'lodged-button'
                    : 'submitted-button'
                }
              >
                {applicationStatus}
              </Button>
            </GridColumn>
          </GridRow>
        </Grid>
      </Header>
      <Formik
        onSubmit={(values) => onSubmit(values)}
        initialValues={initialValues}
        validationSchema={VehicleImportValidationSchema}
      >
        {({ handleSubmit, values, setFieldValue }) => (
          <>
            <Form onSubmit={handleSubmit}>
              <Segment
                className="update-application-form-layout"
                attached
                loading={loading}
              >
                <Grid stackable className="update-application-form">
                  <GridRow columns={3} style={{ paddingBottom: '0px' }}>
                    <GridColumn>
                    
                      <CustomInput
                        id="vinNo"
                        name="vinNo"
                        labelText={
                          <Popup
                            trigger={<span>VIN/Chassis Number</span>}
                            content="Vehicle Identification Number"
                          />
                        }
                        disabled={!isEdit}
                        requiredStar
                      />
                    </GridColumn>
                    <GridColumn>
                      <div className="date-of-arrival">
                        <div className="custom-label">
                          <label className="required-stars">
                            Estimated Date of Arrival
                          </label>
                        </div>
                        <div>
                          <CustomDateComponent
                            id="estimatedDate"
                            name="estimatedDate"
                            value={dayjs(values.vehicleArrivedDate)}
                            onChange={(e) => onDateChange(e)}
                            disabled={!isEdit}
                          />
                        </div>
                      </div>
                    </GridColumn>
                    <GridColumn>
                      <CustomInput
                        id="make"
                        name="make"
                        labelText="Make"
                        disabled={!isEdit}
                        requiredStar
                      />
                    </GridColumn>
                  </GridRow>
                  {/* custom input row 2 */}
                  <GridRow columns={3} style={{ paddingBottom: '0px' }}>
                    <GridColumn>
                      <CustomInput
                        id="model"
                        name="model"
                        labelText="Model"
                        disabled={!isEdit}
                        requiredStar
                      />
                    </GridColumn>
                    <GridColumn>
                      <div className="report-type">
                        <label className="required-stars">
                          Power Source Type
                        </label>
                        <div>
                          <CustomDropdown
                            className="custom-drop-down"
                            id="powerSourceType"
                            name="powerSourceType"
                            options={sourceType}
                            placeholder="Choose an option"
                            selection
                            value={values.powerSourceType}
                            onChange={(e, { value }) =>
                              setFieldValue('powerSourceType', value)
                            }
                            disabled={!isEdit}
                          />
                        </div>
                      </div>
                    </GridColumn>
                    <GridColumn>
                      <CustomInput
                        id="totalNoOfSeats"
                        name="totalNoOfSeats"
                        labelText="Total No of Seats"
                        disabled={!isEdit}
                        requiredStar
                      />
                    </GridColumn>
                  </GridRow>
                  {/* custom input row 3 */}
                  <GridRow>
                    <GridColumn computer={11} tablet={8} mobile={16}>
                      <CustomInput
                        id="notes"
                        name="notes"
                        labelText="Notes"
                        placeholder="Any comments for the reviewer"
                        disabled={!isEdit}
                      />
                    </GridColumn>
                    <GridColumn computer={5} tablet={8} mobile={16}>
                      <div className="custom-check-box-update">
                        <Field name="isMoreThan25">
                          {({ field }) => (
                            <Checkbox
                              {...field}
                              id="isMoreThan25"
                              label="Is this vehicle older than 25 years"
                              checked={field.value} // Set the checkbox value based on the field value
                              onChange={() => {
                                // Toggle the value when the checkbox is clicked
                                const newValue = !field.value;
                                setFieldValue(field.name, newValue);
                              }}
                              disabled={!isEdit}
                            />
                          )}
                        </Field>
                      </div>
                    </GridColumn>
                  </GridRow>
                  {selectedApp.isVehicleOld ? (
                    <GridRow>
                      <GridColumn width={8}>
                        {/* VIN Plate photos */}
                        <ImageUploadComponent
                          id="vinImages"
                          name="vinImages"
                          images={vinPlateImages}
                          setImages={setVinPlateImages}
                          label="Vin Photos"
                          inputRef={vinPlateFileInputRef}
                          onInputChange={(e) =>
                            handleFileInputChange(
                              e,
                              setVinPlateImages,
                              'vinPhotos'
                            )
                          }
                          smallContainer={true}
                          disabled={!isEdit}
                        />
                      </GridColumn>
                      <GridColumn width={8}>
                        {/* Engine bay photos */}
                        <ImageUploadComponent
                          id="engineBayImages"
                          name="engineBayImages"
                          images={ebVehicleImages}
                          setImages={setEbVehicleImages}
                          label="Engine bay photos"
                          inputRef={ebVehicleFileInputRef}
                          onInputChange={(e) =>
                            handleFileInputChange(
                              e,
                              setEbVehicleImages,
                              'engineBay'
                            )
                          }
                          smallContainer={true}
                          disabled={!isEdit}
                        />
                      </GridColumn>
                    </GridRow>
                  ) : null}

                  {/* <InternalVehiclePhotos /> */}
                  <ImageUploadComponent
                    name="interiorImages"
                    images={inVehicleImages}
                    setImages={setInVehicleImages}
                    label="Internal Vehicle Photos"
                    inputRef={inVehicleFileInputRef}
                    onInputChange={(e) =>
                      handleFileInputChange(
                        e,
                        setInVehicleImages,
                        'interiorImage'
                      )
                    }
                    disabled={!isEdit}
                  />

                  {/* External vehicle photos */}
                  <ImageUploadComponent
                    id="exteriorImages"
                    name="exteriorImages"
                    images={exVehicleImages}
                    setImages={setExVehicleImages}
                    label="Exterior Vehicle photos"
                    inputRef={exVehicleFileInputRef}
                    onInputChange={(e) =>
                      handleFileInputChange(
                        e,
                        setExVehicleImages,
                        'exteriorImage'
                      )
                    }
                    smallContainer={true}
                    disabled={!isEdit}
                  />
                  <GridRow columns={3} style={{ paddingBottom: '0px' }}>
                    {/* Export certificate */}
                    <PdfAndImageUploadComponent
                      id="exportCertificate"
                      name="exportCertificate"
                      label="Export certificate"
                      type="export_certificate"
                      handleFileUpload={(e) =>
                        handleFileUpload(e, setExportCertificate)
                      }
                      document={exportCertificate}
                      uploadDocumentInputRef={uploadExportCertificateInputRef}
                      handleDocumentDelete={() =>
                        handleDocumentDelete(
                          setExportCertificate,
                          uploadExportCertificateInputRef
                        )
                      }
                      disabled={!isEdit}
                    />
                    {/* Auction report */}
                    <PdfAndImageUploadComponent
                      id="auctionReport"
                      name="auctionReport"
                      label="Auction report"
                      type="auction_report"
                      handleFileUpload={(e) =>
                        handleFileUpload(e, setAuctionFile)
                      }
                      document={auctionFile}
                      uploadDocumentInputRef={uploadAuctionInputRef}
                      handleDocumentDelete={() =>
                        handleDocumentDelete(
                          setAuctionFile,
                          uploadAuctionInputRef
                        )
                      }
                      disabled={!isEdit}
                    />
                    {/* Vehicle invoice */}
                    <PdfUploadComponent
                      id="vehicleImport"
                      name="vehicleImport"
                      label="Vehicle invoice"
                      type="vehicle_invoice"
                      handleFileUpload={(e) =>
                        handleFileUpload(e, setVehicleInvoice)
                      }
                      document={vehicleInvoice}
                      uploadDocumentInputRef={uploadVehicleInvoiceInputRef}
                      handleDocumentDelete={() =>
                        handleDocumentDelete(
                          setVehicleInvoice,
                          uploadVehicleInvoiceInputRef
                        )
                      }
                      disabled={!isEdit}
                      onlyPdf
                    />
                  </GridRow>

                  {get(user, 'roleId', '') === ADMIN_ROLE ? (
                    <>
                      <Divider />

                      <GridRow style={{ padding: isMobile ? '0px' : '10px' }}>
                        <GridColumn width={12}>
                          <CustomTextAreaContainer>
                            <Grid>
                              <GridRow>
                                <GridColumn width={16}>
                                  <CustomComment
                                    id="comments"
                                    name="comments"
                                    onBlur={(value) => onBlur(value)}
                                  />
                                </GridColumn>
                              </GridRow>
                              <GridRow>
                                <GridColumn floated="right" width={4}>
                                  <Button
                                    className="save-button"
                                    onClick={() => onSaveComment()}
                                  >
                                    Save Comment
                                  </Button>
                                </GridColumn>
                              </GridRow>
                            </Grid>
                          </CustomTextAreaContainer>
                        </GridColumn>
                      </GridRow>
                    </>
                  ) : null}
                </Grid>
              </Segment>
              {/* Footer Section */}
              <Segment
                className="update-application-footer"
                attached="bottom"
                disabled={loading}
              >
                {get(user, 'roleId', '') === ADMIN_ROLE ? (
                  <FooterAdmin
                    user={user}
                    selectedApp={selectedApp}
                    handleRemoveClick={handleRemoveClick}
                    removeLoading={removeLoading}
                    onDownloadAllFiles={onDownloadAllFiles}
                    isDownloading={isDownloading}
                    onClickStatusUpdate={onClickStatusUpdate}
                  />
                ) : (
                  <FooterCustomer
                    onDownloadAllFiles={onDownloadAllFiles}
                    isDownloading={isDownloading}
                  />
                )}
              </Segment>
            </Form>
          </>
        )}
      </Formik>
    </div>
  );
};

UpdateVehicleImportApplication.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.object]),
  className: PropTypes.string,
};

export default UpdateVehicleImportApplication;
