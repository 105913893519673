import React, { useEffect, useRef, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import { Formik, Form } from 'formik';
import {
  Button,
  Checkbox,
  Segment,
  Grid,
  GridRow,
  GridColumn,
} from 'semantic-ui-react';
import Step1Form from './Step1Form';
import Step2Form from './Step2Form';
import ReviewApplicationForm from './ReviewApplicationForm';
import CustomModalInfo from '../../../components/modals/customModalInfo';
import {
  complianceValidationSchemaStep1,
  complianceValidationSchemaStep2,
  complianceInitialValues,
} from '../../../validations/complianceForm/complianceValidation';
import { postComplianceApplication } from '../../../store/modules/applications';
import { createWithFormData, termsConditions } from '../../../utils';
import { STATUS } from '../../../utils/constant';

import '../application.scss';

const ComplianceApplicationForm = () => {
  const [step, setStep] = useState(1);
  // const [fileError, setFileError] = useState('');
  const [status, setStatus] = useState('');
  const [isTermsAgreed, setTermsAgreed] = useState(false);
  const [openTermsModal, setOpenTermsModal] = useState(false);
  const [vehicleArrivedDate, setVehicleArriveDate] = useState(null);
  const [make, setMake] = useState('');
  const [initialFormValues, setInitialFormValues] = useState(
    complianceInitialValues
  );
  const [approvalDocument, setApprovalDocument] = useState(null);
  const [engravedVin, setEngravedVin] = useState(null);
  const [passengerSideAirbag, setPassengerSideAirbag] = useState(null);
  const [compliancePlate, setCompliancePlate] = useState(null);
  const [australianRoadPlate, setAustralianRoadPlate] = useState(null);
  const [tyreOne, setTyreOne] = useState(null);
  const [tyreTwo, setTyreTwo] = useState(null);
  const [tyreThree, setTyreThree] = useState(null);
  const [tyreFour, setTyreFour] = useState(null);
  const [petrolOrDiesel, setPetrolOrDiesel] = useState(null);
  const [odometerReadingUpload, setOdometerReadingUpload] = useState(null);
  const [undercarriageFront, setUndercarriageFront] = useState(null);
  const [undercarriageRear, setUndercarriageRear] = useState(null);
  const [undercarriageLeft, setUndercarriageLeft] = useState(null);
  const [undercarriageRight, setUndercarriageRight] = useState(null);
  const [undercarriageCenter, setUndercarriageCenter] = useState(null);
  const [childAnchorPoints, setChildAnchorPoints] = useState(null);
  const [headlights, setHeadlights] = useState(null);
  const [towConnector, setTowConnector] = useState(null);

  const dispatch = useDispatch();
  const loader = useSelector(
    (state) => state.applications.compliancePostLoading
  );
  const navigate = useNavigate();
  const uploadImportApprovalInputRef = useRef(null);

  // const fileErrors = {
  //   approvalDocument: '',
  //   engravedVin: '',
  //   passengerSideAirbag: '',
  //   compliancePlate: '',
  //   australianRoadPlate: '',
  //   tyreOne: '',
  //   tyreTwo: '',
  //   odometerReadingUpload: '',
  //   undercarriageFront: '',
  //   undercarriageRear: '',
  //   undercarriageLeft: '',
  //   undercarriageRight: '',
  //   undercarriageCenter: '',
  //   childAnchorPoints: '',
  //   headlights: '',
  //   towConnector: '',
  // };

  // Load data from sessionStorage on mount
  useEffect(() => {
    const cachedData = sessionStorage.getItem('complianceForm');
    if (cachedData) {
      try {
        const parsedData = JSON.parse(cachedData);
        setInitialFormValues({
          ...complianceInitialValues,
          ...parsedData.formValues,
        });
        setVehicleArriveDate(parsedData.vehicleArrivedDate || null);
        setMake(parsedData.make || '');
      } catch (error) {
        console.error('Error parsing sessionStorage data:', error);
      }
    }
  }, []);

  useEffect(() => {
    const dataToCache = {
      formValues: initialFormValues,
      vehicleArrivedDate,
      make,
    };
    sessionStorage.setItem('complianceForm', JSON.stringify(dataToCache));
  }, [initialFormValues, vehicleArrivedDate, make]);

  const getValidationSchema = (step) => {
    switch (step) {
      case 1:
        return complianceValidationSchemaStep1;
      case 2:
        return complianceValidationSchemaStep2;
      default:
        return null;
    }
  };

  // Remove data from sessionStorage on unmount
  useEffect(() => {
    return () => {
      sessionStorage.removeItem('complianceForm');
    };
  }, []);

  const handleFileUpload = (event, fileKey) => {
    const file = event.target.files[0];
    if (file) {
      switch (fileKey) {
        case 'approvalDocument':
          setApprovalDocument({ file });
          break;
        case 'engravedVin':
          setEngravedVin({ file });
          break;
        case 'passengerSideAirbag':
          setPassengerSideAirbag({ file });
          break;
        case 'compliancePlate':
          setCompliancePlate({ file });
          break;
        case 'australianRoadPlate':
          setAustralianRoadPlate({ file });
          break;
        case 'tyreOne':
          setTyreOne({ file });
          break;
        case 'tyreTwo':
          setTyreTwo({ file });
          break;
        case 'tyreThree':
          setTyreThree({ file });
          break;
        case 'tyreFour':
          setTyreFour({ file });
          break;
        case 'petrolOrDiesel':
          setPetrolOrDiesel({ file });
          break;
        case 'odometerReadingUpload':
          setOdometerReadingUpload({ file });
          break;
        case 'undercarriageFront':
          setUndercarriageFront({ file });
          break;
        case 'undercarriageRear':
          setUndercarriageRear({ file });
          break;
        case 'undercarriageLeft':
          setUndercarriageLeft({ file });
          break;
        case 'undercarriageRight':
          setUndercarriageRight({ file });
          break;
        case 'undercarriageCenter':
          setUndercarriageCenter({ file });
          break;
        case 'childAnchorPoints':
          setChildAnchorPoints({ file });
          break;
        case 'headlights':
          setHeadlights({ file });
          break;
        case 'towConnector':
          setTowConnector({ file });
          break;
        default:
          break;
      }
    }
  };

  const handleDocumentDelete = (fileKey, inputRef) => {
    switch (fileKey) {
      case 'approvalDocument':
        setApprovalDocument(null);
        break;
      case 'engravedVin':
        setEngravedVin(null);
        break;
      case 'passengerSideAirbag':
        setPassengerSideAirbag(null);
        break;
      case 'compliancePlate':
        setCompliancePlate(null);
        break;
      case 'australianRoadPlate':
        setAustralianRoadPlate(null);
        break;
      case 'tyreOne':
        setTyreOne(null);
        break;
      case 'tyreTwo':
        setTyreTwo(null);
        break;
      case 'tyreThree':
        setTyreThree(null);
        break;
      case 'tyreFour':
        setTyreFour(null);
        break;
      case 'petrolOrDiesel':
        setPetrolOrDiesel(null);
        break;
      case 'odometerReadingUpload':
        setOdometerReadingUpload(null);
        break;
      case 'undercarriageFront':
        setUndercarriageFront(null);
        break;
      case 'undercarriageRear':
        setUndercarriageRear(null);
        break;
      case 'undercarriageLeft':
        setUndercarriageLeft(null);
        break;
      case 'undercarriageRight':
        setUndercarriageRight(null);
        break;
      case 'undercarriageCenter':
        setUndercarriageCenter(null);
        break;
      case 'childAnchorPoints':
        setChildAnchorPoints(null);
        break;
      case 'headlights':
        setHeadlights(null);
        break;
      case 'towConnector':
        setTowConnector(null);
        break;
      default:
        break;
    }

    // Add this check before accessing inputRef.current
    if (inputRef && inputRef.current) {
      inputRef.current.value = null;
    }
  };

  const handleSubmitApplication = async (values) => {
    try {
      const newValues = { ...values, vehicleArrivedDate, status };
      const formData = new FormData();
      createWithFormData(formData, newValues);

      // Append files to FormData
      if (approvalDocument?.file)
        formData.append('approvalDocument', approvalDocument.file);
      if (engravedVin?.file) formData.append('engravedVin', engravedVin.file);
      if (passengerSideAirbag?.file)
        formData.append('passengerSideAirbag', passengerSideAirbag.file);
      if (compliancePlate?.file)
        formData.append('compliancePlate', compliancePlate.file);
      if (australianRoadPlate?.file)
        formData.append('australianRoadPlate', australianRoadPlate.file);
      if (tyreOne?.file) formData.append('tyreOne', tyreOne.file);
      if (tyreTwo?.file) formData.append('tyreTwo', tyreTwo.file);
      if (tyreThree?.file) formData.append('tyreThree', tyreThree.file);
      if (tyreFour?.file) formData.append('tyreFour', tyreFour.file);
      if (petrolOrDiesel?.file)
        formData.append('petrolOrDiesel', petrolOrDiesel.file);
      if (odometerReadingUpload?.file)
        formData.append('odometerReadingUpload', odometerReadingUpload.file);
      if (undercarriageFront?.file)
        formData.append('undercarriageFront', undercarriageFront.file);
      if (undercarriageRear?.file)
        formData.append('undercarriageRear', undercarriageRear.file);
      if (undercarriageLeft?.file)
        formData.append('undercarriageLeft', undercarriageLeft.file);
      if (undercarriageRight?.file)
        formData.append('undercarriageRight', undercarriageRight.file);
      if (undercarriageCenter?.file)
        formData.append('undercarriageCenter', undercarriageCenter.file);
      if (childAnchorPoints?.file)
        formData.append('childAnchorPoints', childAnchorPoints.file);
      if (headlights?.file) formData.append('headlights', headlights.file);
      if (towConnector?.file)
        formData.append('towConnector', towConnector.file);

      // Dispatch the formData with status included
      await dispatch(postComplianceApplication(formData));
      navigate('/applications');
    } catch (err) {
      console.error('Error submitting the compliance form', err);
    }
  };

  const onToggle = () => {
    setOpenTermsModal(!openTermsModal);
  };

  // Add Step 3 (Review) logic
  const renderStep = (formikProps) => {
    if (step === 1) {
      return (
        <Step1Form
          vehicleArrivedDate={vehicleArrivedDate}
          onDateChange={setVehicleArriveDate}
          make={formikProps.values.make || make}
          handleMakeChange={setMake}
          formikProps={formikProps}
        />
      );
    } else if (step === 2) {
      return (
        <Step2Form
          approvalDocument={approvalDocument}
          engravedVin={engravedVin}
          passengerSideAirbag={passengerSideAirbag}
          compliancePlate={compliancePlate}
          australianRoadPlate={australianRoadPlate}
          tyreOne={tyreOne}
          tyreTwo={tyreTwo}
          tyreThree={tyreThree}
          tyreFour={tyreFour}
          petrolOrDiesel={petrolOrDiesel}
          odometerReadingUpload={odometerReadingUpload}
          undercarriageFront={undercarriageFront}
          undercarriageRear={undercarriageRear}
          undercarriageLeft={undercarriageLeft}
          undercarriageRight={undercarriageRight}
          undercarriageCenter={undercarriageCenter}
          childAnchorPoints={childAnchorPoints}
          headlights={headlights}
          towConnector={towConnector}
          handleFileUpload={handleFileUpload}
          handleDocumentDelete={handleDocumentDelete}
          uploadImportApprovalInputRef={uploadImportApprovalInputRef}
          formikProps={formikProps}
        />
      );
    } else if (step === 3) {
      return (
        <ReviewApplicationForm
          formValues={formikProps.values}
          vehicleArrivedDate={vehicleArrivedDate}
          make={make}
          approvalDocument={approvalDocument}
        />
      );
    }
  };

  const renderFooter = (formikProps) => {
    return (
      <Segment attached="bottom" disabled={loader}>
        <Grid>
          <GridRow columns={2}>
            <GridColumn width={8}>
              {step > 1 && (
                <Button
                  onClick={() => {
                    const dataToCache = {
                      formValues: formikProps.values,
                      vehicleArrivedDate,
                      make,
                    };
                    sessionStorage.setItem(
                      'complianceForm',
                      JSON.stringify(dataToCache)
                    );
                    setStep(step - 1);
                  }}
                  basic
                >
                  Back
                </Button>
              )}
            </GridColumn>
            <GridColumn width={8} textAlign="right">
              {step === 3 && (
                <div>
                  <Button
                    secondary
                    onClick={() => {
                      setStatus(STATUS.LODGED);
                      formikProps.handleSubmit();
                    }}
                    disabled={loader}
                    loading={loader}
                  >
                    Save as Draft
                  </Button>
                  <Button
                    secondary
                    onClick={() => {
                      setStatus(STATUS.SUBMITTED);
                    }}
                    disabled={loader}
                    loading={loader}
                    style={{ marginLeft: '10px' }}
                  >
                    Submit Application
                  </Button>
                </div>
              )}
              {step < 3 && (
                <>
                  <GridRow>
                    {step === 2 && (
                      <GridColumn width={16}>
                        <div className="custom-check-box">
                          <Checkbox
                            name="termsConditions"
                            label=""
                            className="terms-checkbox"
                            value={isTermsAgreed}
                            onChange={() => setTermsAgreed(!isTermsAgreed)}
                          />
                          <label
                            className="terms"
                            onClick={() => setOpenTermsModal((prev) => !prev)}
                          >
                            Terms & Conditions
                          </label>
                          <div className="button-container">
                            <Button
                              secondary
                              disabled={!isTermsAgreed}
                              onClick={async () => {
                                const errors = await formikProps.validateForm();
                                if (Object.keys(errors).length === 0) {
                                  const dataToCache = {
                                    formValues: formikProps.values,
                                    vehicleArrivedDate,
                                    make,
                                  };
                                  sessionStorage.setItem(
                                    'complianceForm',
                                    JSON.stringify(dataToCache)
                                  );
                                  setStep(step + 1);
                                } else {
                                  formikProps.setTouched(errors);
                                }
                              }}
                            >
                              Review Application
                            </Button>
                          </div>
                        </div>
                      </GridColumn>
                    )}
                    {step !== 2 && (
                      <GridColumn width={16} textAlign="right">
                        <Button
                          secondary
                          onClick={async () => {
                            const errors = await formikProps.validateForm();
                            if (Object.keys(errors).length === 0) {
                              const dataToCache = {
                                formValues: formikProps.values,
                                vehicleArrivedDate,
                                make,
                              };
                              sessionStorage.setItem(
                                'complianceForm',
                                JSON.stringify(dataToCache)
                              );
                              setStep(step + 1);
                            } else {
                              formikProps.setTouched(errors);
                            }
                          }}
                        >
                          Next
                        </Button>
                      </GridColumn>
                    )}
                  </GridRow>
                </>
              )}
            </GridColumn>
          </GridRow>
        </Grid>
      </Segment>
    );
  };

  return (
    <div className="compliance-application">
      <div className="update-application">
        <CustomModalInfo
          open={openTermsModal}
          header="Terms & Conditions"
          content={termsConditions()}
          onToggle={onToggle}
        />
        <Formik
          initialValues={initialFormValues}
          enableReinitialize
          validationSchema={getValidationSchema(step)}
          onSubmit={(values) => {
            if (step === 3) {
              handleSubmitApplication(values);
            }
          }}
        >
          {(formikProps) => {
            return (
              <Form>
                {renderStep(formikProps)}
                {renderFooter(formikProps)}
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
};

export default ComplianceApplicationForm;
