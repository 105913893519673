import React from 'react';
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs';
import { DatePicker } from '@mui/x-date-pickers/DatePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useField } from 'formik';
import dayjs from 'dayjs';

const CustomDateComponent = (props) => {
  const [, meta, helpers] = useField({ ...props });
  const { setValue } = helpers;

  return (
    <div className="date-picker">
      <LocalizationProvider dateAdapter={AdapterDayjs}>
        <DatePicker
          // label="Date"
          value={props.value ? dayjs(props.value) : null}
          onChange={(date) => {
            const formattedDate = date
              ? dayjs(date).format('YYYY-MM-DD')
              : null;
            setValue(formattedDate);
            props.onChange(formattedDate);
          }}
          format="DD-MM-YYYY"
          disabled={props.disabled}
          slotProps={{ textField: { size: 'small' } }}
        />
      </LocalizationProvider>
      {meta.touched && meta.error && (
        <div className="invalid-feedback">{meta.error}</div>
      )}
    </div>
  );
};

export default CustomDateComponent;
