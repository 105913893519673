import React, { useRef, useState, useEffect } from 'react';
import { Grid, GridColumn, GridRow, Segment } from 'semantic-ui-react';
import FileUploadComponent from '../../../components/fileUpload/fileUploadComponent';

const Step2Form = ({
  approvalDocument,
  engravedVin,
  passengerSideAirbag,
  compliancePlate,
  australianRoadPlate,
  tyreOne,
  tyreTwo,
  tyreThree,
  tyreFour,
  petrolOrDiesel,
  odometerReadingUpload,
  undercarriageFront,
  undercarriageRear,
  undercarriageLeft,
  undercarriageRight,
  undercarriageCenter,
  childAnchorPoints,
  headlights,
  towConnector,
  handleFileUpload,
  handleDocumentDelete,
  formikProps,
  readOnly,
  updateData,
}) => {
  const uploadImportApprovalInputRef = useRef();
  const uploadEngravedVinInputRef = useRef();
  const uploadPassengerSideAirbagInputRef = useRef();
  const uploadCompliancePlateInputRef = useRef();
  const uploadAustralianRoadPlateInputRef = useRef();
  const uploadTyreOneInputRef = useRef();
  const uploadTyreTwoInputRef = useRef();
  const uploadTyreThreeInputRef = useRef();
  const uploadTyreFourInputRef = useRef();
  const uploadPetrolOrDieselInputRef = useRef();
  const uploadOdometerReadingUploadInputRef = useRef();
  const uploadUndercarriageFrontInputRef = useRef();
  const uploadUndercarriageRearInputRef = useRef();
  const uploadUndercarriageLeftInputRef = useRef();
  const uploadUndercarriageRightInputRef = useRef();
  const uploadUndercarriageCenterInputRef = useRef();
  const uploadChildAnchorPointsInputRef = useRef();
  const uploadHeadlightsInputRef = useRef();
  const uploadTowConnectorInputRef = useRef();

  const [formData, setFormData] = useState({
    approvalDocument,
    engravedVin,
    passengerSideAirbag,
    compliancePlate,
    australianRoadPlate,
    tyreOne,
    tyreTwo,
    tyreThree,
    tyreFour,
    petrolOrDiesel,
    odometerReadingUpload,
    undercarriageFront,
    undercarriageRear,
    undercarriageLeft,
    undercarriageRight,
    undercarriageCenter,
    childAnchorPoints,
    headlights,
    towConnector,
  });

  const [isSessionStorageLoaded, setIsSessionStorageLoaded] = useState(false);

  // Load data from session storage if available
  useEffect(() => {
    const cachedData = sessionStorage.getItem('complianceForm');
    if (cachedData) {
      try {
        const parsedData = JSON.parse(cachedData);
        setFormData(parsedData);
        setIsSessionStorageLoaded(true);
      } catch (error) {
        console.error('Error parsing sessionStorage data:', error);
      }
    }
  }, [updateData]);

  // Form for session storage loaded values
  const renderSessionStorageForm = () => {
    if (formData) {
      return (
        <Segment className="update-application-form-layout" attached>
          <div className="flex-container">
            <h2 className="compliance-title">Compliance Application</h2>
            <h4 className="step-indicator">Step 2 of 2</h4>
          </div>
          <hr />
          <Grid className="update-application-form" stackable>
            <GridRow columns={1}>
              <GridColumn width={5}>
                <FileUploadComponent
                  name="approvalDocument"
                  label="Existing Vehicle import approval?"
                  subLabel={` Only add if obtained outside of Import revolution`}
                  document={formData?.approvalDocument?.[0] || ''}
                  requiredStar
                  readOnly={readOnly}
                />
              </GridColumn>
            </GridRow>
            {/* Additional form fields for documents */}
            <GridRow columns={3}>
              <GridColumn width={5}>
                <FileUploadComponent
                  name="engravedVin"
                  label="Engraved/stamped/Vin plate of 17 digit vin no"
                  document={formData?.engravedVin?.[0] || ''}
                  requiredStar
                  readOnly={readOnly}
                />
              </GridColumn>
              <GridColumn width={5}>
                <FileUploadComponent
                  name="passengerSideAirbag"
                  label="Passenger side airbag sticker located on side of the dashboard"
                  document={formData?.passengerSideAirbag?.[0] || ''}
                  requiredStar
                  readOnly={readOnly}
                />
              </GridColumn>
              <GridColumn width={5}>
                <FileUploadComponent
                  name="compliancePlate"
                  label="Passenger side ENGLISH airbag sticker located on the passenger side"
                  document={formData?.compliancePlate?.[0] || ''}
                  requiredStar
                  readOnly={readOnly}
                />
              </GridColumn>
            </GridRow>

            <GridRow columns={3}>
              <GridColumn width={5}>
                <FileUploadComponent
                  name="australianRoadPlate"
                  label="Australian (English) tyre placard (NOT the Japanese tyre placard)"
                  document={formData?.australianRoadPlate?.[0] || ''}
                  requiredStar
                  readOnly={readOnly}
                />
              </GridColumn>
              <GridColumn width={5}>
                <FileUploadComponent
                  name="tyreOne"
                  label="Date code & tyre thread (Tyre 1)"
                  document={formData?.tyreOne?.[0] || ''}
                  requiredStar
                  readOnly={readOnly}
                />
              </GridColumn>
              <GridColumn width={5}>
                <FileUploadComponent
                  name="tyreTwo"
                  label="Date code & tyre thread (Tyre 2)"
                  document={formData?.tyreTwo?.[0] || ''}
                  requiredStar
                  readOnly={readOnly}
                />
              </GridColumn>
            </GridRow>

            <GridRow columns={3}>
              <GridColumn width={5}>
                <FileUploadComponent
                  name="tyreThree"
                  label="Date code & tyre thread (Tyre 3)"
                  document={formData?.tyreThree?.[0] || ''}
                  requiredStar
                  readOnly={readOnly}
                />
              </GridColumn>
              <GridColumn width={5}>
                <FileUploadComponent
                  name="tyreFour"
                  label="Date code & tyre thread (Tyre 4)"
                  document={formData?.tyreFour?.[0] || ''}
                  requiredStar
                  readOnly={readOnly}
                />
              </GridColumn>
              <GridColumn width={5}>
                <FileUploadComponent
                  name="petrolOrDiesel"
                  label="Unleaded petrol fuel sticker or Diesel fuel sticker (in English)"
                  document={formData?.petrolOrDiesel?.[0] || ''}
                  requiredStar
                  readOnly={readOnly}
                />
              </GridColumn>
            </GridRow>

            <GridRow columns={3}>
              <GridColumn width={5}>
                <FileUploadComponent
                  name="odometerReadingUpload"
                  label="Odometer Reading"
                  document={formData?.odometerReadingUpload?.[0] || ''}
                  requiredStar
                  readOnly={readOnly}
                />
              </GridColumn>
              <GridColumn width={5}>
                <FileUploadComponent
                  name="undercarriageFront"
                  label="Under Carriage (Front)"
                  document={formData?.undercarriageFront?.[0] || ''}
                  requiredStar
                  readOnly={readOnly}
                />
              </GridColumn>
              <GridColumn width={5}>
                <FileUploadComponent
                  name="undercarriageRear"
                  label="Under Carriage (Rear)"
                  document={formData?.undercarriageRear?.[0] || ''}
                  requiredStar
                  readOnly={readOnly}
                />
              </GridColumn>
            </GridRow>

            <GridRow columns={3}>
              <GridColumn width={5}>
                <FileUploadComponent
                  name="undercarriageLeft"
                  label="Under Carriage (Left)"
                  document={formData?.undercarriageLeft?.[0] || ''}
                  requiredStar
                  readOnly={readOnly}
                />
              </GridColumn>
              <GridColumn width={5}>
                <FileUploadComponent
                  name="undercarriageRight"
                  label="Under Carriage (Right)"
                  document={formData?.undercarriageRight?.[0] || ''}
                  requiredStar
                  readOnly={readOnly}
                />
              </GridColumn>
              <GridColumn width={5}>
                <FileUploadComponent
                  name="undercarriageCenter"
                  label="Under Carriage (Center)"
                  document={formData?.undercarriageCenter?.[0] || ''}
                  requiredStar
                  readOnly={readOnly}
                />
              </GridColumn>
            </GridRow>
            <hr />
            <GridRow columns={1}>
              <GridColumn width={16}>
                <h4>Optional Pictures</h4>
              </GridColumn>
            </GridRow>
            <GridRow columns={3}>
              <GridColumn width={5}>
                <FileUploadComponent
                  name="childAnchorPoints"
                  label="Child Anchorage Points"
                  document={formData?.childAnchorPoints?.[0] || ''}
                  readOnly={readOnly}
                />
              </GridColumn>
              <GridColumn width={5}>
                <FileUploadComponent
                  name="headlights"
                  label="HID to Halogen Converted Headlights"
                  document={formData?.headlights?.[0] || ''}
                  readOnly={readOnly}
                />
              </GridColumn>
              <GridColumn width={5}>
                <FileUploadComponent
                  name="towConnector"
                  label="New CSA/Tow Connector"
                  document={formData?.towConnector?.[0] || ''}
                  readOnly={readOnly}
                />
              </GridColumn>
            </GridRow>
          </Grid>
        </Segment>
      );
    }
  };

  const renderExistingForm = () => {
    return (
      <Segment className="update-application-form-layout" attached>
        <div className="flex-container">
          <h2 className="compliance-title">Compliance Application</h2>
          <h4 className="step-indicator">Step 2 of 2</h4>
        </div>
        <hr />
        <Grid className="update-application-form" stackable>
          <GridRow columns={1}>
            <GridColumn width={4}>
              <FileUploadComponent
                name="approvalDocument"
                label="Existing Vehicle import approval?"
                subLabel={` Only
    add if obtained outside of Import revolution`}
                handleFileUpload={(e) =>
                  handleFileUpload(e, 'approvalDocument')
                }
                document={approvalDocument}
                uploadDocumentInputRef={uploadImportApprovalInputRef}
                handleDocumentDelete={() =>
                  handleDocumentDelete(
                    'approvalDocument',
                    uploadImportApprovalInputRef
                  )
                }
                requiredStar
                readOnly={readOnly}
                error={
                  formikProps?.errors?.approvalDocument &&
                  formikProps?.touched?.approvalDocument
                    ? formikProps.errors.approvalDocument
                    : null
                }
              />
            </GridColumn>
          </GridRow>
          <hr />
          {/* Uploads for additional documents and pictures */}
          <GridRow columns={4}>
            <GridColumn width={4}>
              <FileUploadComponent
                name="engravedVin"
                label="Engraved/stamped/Vin plate of 17 digit
    vin no "
                handleFileUpload={(e) => handleFileUpload(e, 'engravedVin')}
                document={engravedVin}
                uploadDocumentInputRef={uploadEngravedVinInputRef}
                handleDocumentDelete={() => handleDocumentDelete('engravedVin')}
                requiredStar
                readOnly={readOnly}
                error={
                  formikProps?.errors?.engravedVin &&
                  formikProps?.touched?.engravedVin
                    ? formikProps.errors.engravedVin
                    : null
                }
              />
            </GridColumn>
            <GridColumn width={4}>
              <FileUploadComponent
                name="passengerSideAirbag"
                label="passenger side airbag sticker located on
    side of the dashboard"
                handleFileUpload={(e) =>
                  handleFileUpload(e, 'passengerSideAirbag')
                }
                document={passengerSideAirbag}
                uploadDocumentInputRef={uploadPassengerSideAirbagInputRef}
                handleDocumentDelete={() =>
                  handleDocumentDelete('passengerSideAirbag')
                }
                requiredStar
                readOnly={readOnly}
                error={
                  formikProps?.errors?.passengerSideAirbag &&
                  formikProps?.touched?.passengerSideAirbag
                    ? formikProps.errors.passengerSideAirbag
                    : null
                }
              />
            </GridColumn>
            <GridColumn width={4}>
              <FileUploadComponent
                name="compliancePlate"
                label="Passenger side ENGLISH airbag sticker
    located on the passenger side"
                handleFileUpload={(e) => handleFileUpload(e, 'compliancePlate')}
                document={compliancePlate}
                uploadDocumentInputRef={uploadCompliancePlateInputRef}
                handleDocumentDelete={() =>
                  handleDocumentDelete('compliancePlate')
                }
                requiredStar
                readOnly={readOnly}
                error={
                  formikProps?.errors?.compliancePlate &&
                  formikProps?.touched?.compliancePlate
                    ? formikProps.errors.compliancePlate
                    : null
                }
              />
            </GridColumn>
            <GridColumn width={4}>
              <FileUploadComponent
                name="australianRoadPlate"
                label="Australian (English) tyre placard (NOT the
    Japanese tyre placard)"
                handleFileUpload={(e) =>
                  handleFileUpload(e, 'australianRoadPlate')
                }
                document={australianRoadPlate}
                uploadDocumentInputRef={uploadAustralianRoadPlateInputRef}
                handleDocumentDelete={() =>
                  handleDocumentDelete('australianRoadPlate')
                }
                requiredStar
                readOnly={readOnly}
                error={
                  formikProps?.errors?.australianRoadPlate &&
                  formikProps?.touched?.australianRoadPlate
                    ? formikProps.errors.australianRoadPlate
                    : null
                }
              />
            </GridColumn>
          </GridRow>
          {/* Additional file uploads for tyres, odometer, and undercarriage */}
          <GridRow columns={4}>
            <GridColumn width={4}>
              <FileUploadComponent
                name="tyreOne"
                label="Date code & tyre thread "
                subLabel={` (Tyre 1)`}
                handleFileUpload={(e) => handleFileUpload(e, 'tyreOne')}
                document={tyreOne}
                uploadDocumentInputRef={uploadTyreOneInputRef}
                handleDocumentDelete={() => handleDocumentDelete('tyreOne')}
                requiredStar
                readOnly={readOnly}
                error={
                  formikProps?.errors?.tyreOne && formikProps?.touched?.tyreOne
                    ? formikProps.errors.tyreOne
                    : null
                }
              />
            </GridColumn>
            <GridColumn width={4}>
              <FileUploadComponent
                name="tyreTwo"
                label="Date code & tyre thread "
                subLabel={` Tyre 2)`}
                handleFileUpload={(e) => handleFileUpload(e, 'tyreTwo')}
                document={tyreTwo}
                uploadDocumentInputRef={uploadTyreTwoInputRef}
                handleDocumentDelete={() => handleDocumentDelete('tyreTwo')}
                requiredStar
                readOnly={readOnly}
                error={
                  formikProps?.errors?.tyreTwo && formikProps?.touched?.tyreTwo
                    ? formikProps.errors.tyreTwo
                    : null
                }
              />
            </GridColumn>
            <GridColumn width={4}>
              <FileUploadComponent
                name="tyreThree"
                label="Date code & tyre thread "
                subLabel={` (Tyre 3)`}
                handleFileUpload={(e) => handleFileUpload(e, 'tyreThree')}
                document={tyreThree}
                uploadDocumentInputRef={uploadTyreThreeInputRef}
                handleDocumentDelete={() => handleDocumentDelete('tyreThree')}
                requiredStar
                readOnly={readOnly}
                error={
                  formikProps?.errors?.tyreThree &&
                  formikProps?.touched?.tyreThree
                    ? formikProps.errors.tyreThree
                    : null
                }
              />
            </GridColumn>
            <GridColumn width={4}>
              <FileUploadComponent
                name="tyreFour"
                label="Date code & tyre thread "
                subLabel={` (Tyre 4)`}
                handleFileUpload={(e) => handleFileUpload(e, 'tyreFour')}
                document={tyreFour}
                uploadDocumentInputRef={uploadTyreFourInputRef}
                handleDocumentDelete={() => handleDocumentDelete('tyreFour')}
                requiredStar
                readOnly={readOnly}
                error={
                  formikProps?.errors?.tyreFour &&
                  formikProps?.touched?.tyreFour
                    ? formikProps.errors.tyreFour
                    : null
                }
              />
            </GridColumn>
          </GridRow>
          <GridRow columns={4}>
            <GridColumn width={4}>
              <FileUploadComponent
                name="petrolOrDiesel"
                label="Unleaded petrol fuel sticker or Diesel
    fuel sticker"
                subLabel={` in English`}
                handleFileUpload={(e) => handleFileUpload(e, 'petrolOrDiesel')}
                document={petrolOrDiesel}
                uploadDocumentInputRef={uploadPetrolOrDieselInputRef}
                handleDocumentDelete={() =>
                  handleDocumentDelete('petrolOrDiesel')
                }
                requiredStar
                readOnly={readOnly}
                error={
                  formikProps?.errors?.petrolOrDiesel &&
                  formikProps?.touched?.petrolOrDiesel
                    ? formikProps.errors.petrolOrDiesel
                    : null
                }
              />
            </GridColumn>
            <GridColumn width={4}>
              <FileUploadComponent
                name="odometerReadingUpload"
                label="Odometer Reading"
                handleFileUpload={(e) =>
                  handleFileUpload(e, 'odometerReadingUpload')
                }
                document={odometerReadingUpload}
                uploadDocumentInputRef={uploadOdometerReadingUploadInputRef}
                handleDocumentDelete={() =>
                  handleDocumentDelete('odometerReadingUpload')
                }
                requiredStar
                readOnly={readOnly}
                error={
                  formikProps?.errors?.odometerReadingUpload &&
                  formikProps?.touched?.odometerReadingUpload
                    ? formikProps.errors.odometerReadingUpload
                    : null
                }
              />
            </GridColumn>
            <GridColumn width={4}>
              <FileUploadComponent
                name="undercarriageFront"
                label="Under Carriage "
                subLabel={` Front`}
                handleFileUpload={(e) =>
                  handleFileUpload(e, 'undercarriageFront')
                }
                document={undercarriageFront}
                uploadDocumentInputRef={uploadUndercarriageFrontInputRef}
                handleDocumentDelete={() =>
                  handleDocumentDelete('undercarriageFront')
                }
                requiredStar
                readOnly={readOnly}
                error={
                  formikProps?.errors?.undercarriageFront &&
                  formikProps?.touched?.undercarriageFront
                    ? formikProps.errors.undercarriageFront
                    : null
                }
              />
            </GridColumn>
            <GridColumn width={4}>
              <FileUploadComponent
                name="undercarriageRear"
                label="Under Carriage"
                subLabel={` Rear`}
                handleFileUpload={(e) =>
                  handleFileUpload(e, 'undercarriageRear')
                }
                document={undercarriageRear}
                uploadDocumentInputRef={uploadUndercarriageRearInputRef}
                handleDocumentDelete={() =>
                  handleDocumentDelete('undercarriageRear')
                }
                requiredStar
                readOnly={readOnly}
                error={
                  formikProps?.errors?.undercarriageRear &&
                  formikProps?.touched?.undercarriageRear
                    ? formikProps.errors.undercarriageRear
                    : null
                }
              />
            </GridColumn>
          </GridRow>
          <GridRow columns={4}>
            <GridColumn width={4}>
              <FileUploadComponent
                name="undercarriageLeft"
                label="Under Carriage "
                subLabel={` Left`}
                handleFileUpload={(e) =>
                  handleFileUpload(e, 'undercarriageLeft')
                }
                document={undercarriageLeft}
                uploadDocumentInputRef={uploadUndercarriageLeftInputRef}
                handleDocumentDelete={() =>
                  handleDocumentDelete('undercarriageLeft')
                }
                requiredStar
                readOnly={readOnly}
                error={
                  formikProps?.errors?.undercarriageLeft &&
                  formikProps?.touched?.undercarriageLeft
                    ? formikProps.errors.undercarriageLeft
                    : null
                }
              />
            </GridColumn>
            <GridColumn width={4}>
              <FileUploadComponent
                name="undercarriageRight"
                label="Under Carriage "
                subLabel={` Right`}
                handleFileUpload={(e) =>
                  handleFileUpload(e, 'undercarriageRight')
                }
                document={undercarriageRight}
                uploadDocumentInputRef={uploadUndercarriageRightInputRef}
                handleDocumentDelete={() =>
                  handleDocumentDelete('undercarriageRight')
                }
                requiredStar
                readOnly={readOnly}
                error={
                  formikProps?.errors?.undercarriageRight &&
                  formikProps?.touched?.undercarriageRight
                    ? formikProps.errors.undercarriageRight
                    : null
                }
              />
            </GridColumn>
            <GridColumn width={4}>
              <FileUploadComponent
                name="undercarriageCenter"
                label="Under Carriage "
                subLabel={` Center`}
                handleFileUpload={(e) =>
                  handleFileUpload(e, 'undercarriageCenter')
                }
                document={undercarriageCenter}
                uploadDocumentInputRef={uploadUndercarriageCenterInputRef}
                handleDocumentDelete={() =>
                  handleDocumentDelete('undercarriageCenter')
                }
                requiredStar
                readOnly={readOnly}
                error={
                  formikProps?.errors?.undercarriageCenter &&
                  formikProps?.touched?.undercarriageCenter
                    ? formikProps.errors.undercarriageCenter
                    : null
                }
              />
            </GridColumn>
          </GridRow>
          <hr />
          {/* Optional Pictures */}
          <GridRow columns={1}>
            <GridColumn width={16}>
              <h4>Optional Pictures</h4>
            </GridColumn>
          </GridRow>
          <GridRow columns={4}>
            <GridColumn width={4}>
              <FileUploadComponent
                name="childAnchorPoints"
                label="Child Anchorage Points"
                handleFileUpload={(e) =>
                  handleFileUpload(e, 'childAnchorPoints')
                }
                document={childAnchorPoints}
                uploadDocumentInputRef={uploadChildAnchorPointsInputRef}
                handleDocumentDelete={() =>
                  handleDocumentDelete('childAnchorPoints')
                }
                readOnly={readOnly}
              />
            </GridColumn>
            <GridColumn width={4}>
              <FileUploadComponent
                name="headlights"
                label="HID to Halogen Converted Headlights"
                handleFileUpload={(e) => handleFileUpload(e, 'headlights')}
                document={headlights}
                uploadDocumentInputRef={uploadHeadlightsInputRef}
                handleDocumentDelete={() => handleDocumentDelete('headlights')}
                readOnly={readOnly}
              />
            </GridColumn>
            <GridColumn width={4}>
              <FileUploadComponent
                name="towConnector"
                label="New CSA/Tow Connector"
                handleFileUpload={(e) => handleFileUpload(e, 'towConnector')}
                document={towConnector}
                uploadDocumentInputRef={uploadTowConnectorInputRef}
                handleDocumentDelete={() =>
                  handleDocumentDelete('towConnector')
                }
                readOnly={readOnly}
              />
            </GridColumn>
          </GridRow>
        </Grid>
      </Segment>
    );
  };

  return (
    <>
      {isSessionStorageLoaded && updateData
        ? renderSessionStorageForm()
        : renderExistingForm()}
    </>
  );
};

export default Step2Form;
